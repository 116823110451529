import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormHelperText } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';

import { sortBy } from './utils';
import GenericLink from '../components/GenericLink';

import * as styles from './forms.module.scss';

type FieldValue = string | number | boolean;
type Field = GenericField<string> | GenericField<number> | GenericField<boolean>;
type SubmitState = 'disabled' | 'ready' | 'submitting' | 'failed' | 'submitted';

type GenericField<T extends FieldValue> = {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  validations?: Readonly<Array<'required' | 'email'>>;
};

export function useFormField<T extends FieldValue>(
  initialValue: T,
  validations: Readonly<Array<'required' | 'email'>>,
): GenericField<T> {
  const [value, setValue] = useState<T>(initialValue);
  const [error, setError] = useState<string>('');

  return {
    value,
    setValue,
    error,
    setError,
    validations,
  };
}

export function useForm<FieldName extends string>({
  fieldsByName,
  onSubmit,
}: {
  fieldsByName: Record<FieldName, Field>;
  onSubmit: () => void;
}): {
  getFieldProps: typeof getFieldProps;
  renderSubmitButton: typeof renderSubmitButton;
} {
  const fields = sortBy(
    Object.entries(fieldsByName) as Array<[FieldName, GenericField<FieldValue>]>,
    ([key]) => key,
  ).map(([, field]) => field);

  const [formError, setFormError] = useState('');
  const [submitState, setSubmitState] = useState<SubmitState>(
    checkFormHasErrors() ? 'disabled' : 'ready',
  );

  function getFieldErrorMsg<T extends FieldValue>(field: GenericField<T>): string | null {
    const { value, validations } = field;
    if (!validations) {
      return null;
    }
    for (const validation of validations) {
      switch (validation) {
        case 'required':
          if (!value) {
            return 'This field is required.';
          }
          break;
        case 'email':
          if (typeof value === 'string' && !value.match(/^\S+@\S+\.\S+$/)) {
            return 'Invalid email (e.g. email@example.com)';
          }
          break;
      }
    }
    return null;
  }

  function onFieldUnfocus<T extends FieldValue>(field: GenericField<T>): void {
    const errorMsg = getFieldErrorMsg(field);
    if (errorMsg !== null) {
      field.setError(errorMsg);
    }
  }

  function checkFormHasErrors(): boolean {
    for (const field of fields) {
      if (getFieldErrorMsg(field) !== null) {
        return true;
      }
    }
    return false;
  }

  async function internalOnSubmit(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (submitState !== 'ready' || checkFormHasErrors()) {
      return;
    }

    setSubmitState('submitting');

    try {
      await onSubmit();
      setSubmitState('submitted');
    } catch (error) {
      setSubmitState('failed');
      if (error && error.message === 'Failed to fetch') {
        setFormError('Network failed to send your request.');
      } else {
        setFormError('An unknown error has occurred.');
      }
    }
  }

  function renderSubmitButton({
    readyLabel,
    submittingLabel,
    errorLabel,
    submittedLabel,
  }: {
    readyLabel: string;
    submittingLabel: string;
    errorLabel: string;
    submittedLabel: string;
  }) {
    return (
      <>
        <a
          className={clsx(styles.submitButton, {
            [styles.submitButtonDisabled]: submitState === 'disabled',
            [styles.submitButtonReady]: submitState === 'ready',
            [styles.submitButtonSubmitting]: submitState === 'submitting',
            [styles.submitButtonFailed]: submitState === 'failed',
            [styles.submitButtonSubmitted]: submitState === 'submitted',
          })}
          type="submit"
          tabIndex={0}
          onClick={internalOnSubmit}
        >
          {(submitState === 'disabled' || submitState === 'ready') && readyLabel}
          {submitState === 'submitting' && (
            <>
              <AutorenewIcon className={styles.submitButtonLoadingIcon}></AutorenewIcon>
              {submittingLabel}
            </>
          )}
          {submitState === 'failed' && (
            <>
              <ErrorIcon></ErrorIcon>
              {errorLabel}
            </>
          )}
          {submitState === 'submitted' && (
            <>
              <CheckIcon></CheckIcon>
              {submittedLabel}
            </>
          )}
        </a>
        <div className={styles.formErrors}>
          <FormHelperText>{formError || ' '}</FormHelperText>
          <FormHelperText>
            {!!formError ? (
              <>
                Please{' '}
                <GenericLink
                  key="link"
                  styleOnly
                  onClick={() => {
                    if (checkFormHasErrors()) {
                      setSubmitState('disabled');
                    } else {
                      setSubmitState('ready');
                    }
                    setFormError('');
                  }}
                >
                  try again
                </GenericLink>
                .
              </>
            ) : (
              ' '
            )}
          </FormHelperText>
        </div>
      </>
    );
  }

  function getFieldProps<T extends FieldValue>(
    field: GenericField<T>,
    options?: {
      setValuePreprocessor?: (value: T) => T;
      defaultHelperText?: string;
    },
  ): {
    disabled: boolean;
    value: T;
    onChange: (value: T) => void;
    onBlur: () => void;
    error: boolean;
    helperText: string;
  } {
    return {
      disabled: submitState === 'submitted',
      value: field.value,
      onChange: (value: T) => {
        field.setValue(
          options && options.setValuePreprocessor ? options.setValuePreprocessor(value) : value,
        );
        field.setError('');
      },
      onBlur: () => onFieldUnfocus(field),
      error: !!field.error,
      helperText: field.error || (options && options.defaultHelperText) || ' ',
    };
  }

  useEffect(
    () => {
      if (!checkFormHasErrors() && submitState === 'disabled') {
        setSubmitState('ready');
      } else if (checkFormHasErrors() && submitState === 'ready') {
        setSubmitState('disabled');
      }
    },
    fields.map(field => field.value),
  );

  return {
    getFieldProps,
    renderSubmitButton,
  };
}
