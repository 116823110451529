// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var contact = "index-module--contact--C51IO";
export var contactBlock = "index-module--contactBlock--UMr60";
export var contactBlockTitle = "index-module--contactBlockTitle--zHrBy";
export var contactGroup = "index-module--contactGroup--3qQt1";
export var contactLink = "index-module--contactLink--o7L2v";
export var contactName = "index-module--contactName--AfDpM";
export var contactsContainer = "index-module--contactsContainer--y57mL";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var department = "index-module--department--o+UOG";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--bwAZt";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";