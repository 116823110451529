// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--OC9i8";
export var content = "index-module--content--ed+TR";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var entry = "index-module--entry--dLhGv";
export var entryText = "index-module--entryText--JbtA-";
export var entryTitle = "index-module--entryTitle--I9TWq";
export var entryTitleContainer = "index-module--entryTitleContainer--dV9v4";
export var error = "#f66a83";
export var firstTriangle = "index-module--firstTriangle--7Gggc";
export var green = "#3dae2b";
export var grey = "#828382";
export var lastTriangle = "index-module--lastTriangle--JWOt-";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";