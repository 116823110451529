import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { RawPortableText } from '../../../types';
import { GenericLinkSerializer, PageLinkSerializer } from '../../../serializers';
import useDimensions from 'react-use-dimensions';
import BlockContent from '@sanity/block-content-to-react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import * as styles from './index.module.scss';

const TEXT_BOX_PADDING = 60;

export const AccordionsModuleFragment = graphql`
  fragment AccordionsModule on SanityAccordionsModule {
    title {
      ...LocaleStringFragment
    }
    width
    imageStartSide
    content {
      title {
        ...LocaleStringFragment
      }
      accordionPortableText {
        ...LocaleSimplePortableTextWithLinks
      }
      image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1240
            height: 640
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    listOfLinks {
      title {
        ...LocaleStringFragment
      }
      placeholder {
        ...LocaleStringFragment
      }
      content {
        title {
          ...LocaleStringFragment
        }
        url
      }
    }
  }
`;

export interface AccordionsModuleProps {
  title?: string;
  width: 'fullwidth' | 'contained';
  imageStartSide: string;
  content: Array<{
    title: string;
    accordionPortableText: RawPortableText;
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  }>;
  listOfLinks?: {
    title?: string;
    placeholder: string;
    content: Array<{
      title: string;
      url: string;
    }>;
  };
}

const AccordionsModule = ({
  title,
  width,
  imageStartSide,
  content,
  listOfLinks,
}: AccordionsModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const [url, setUrl] = useState('');

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container + (width === 'fullwidth' ? +' ' + styles.fullWidth : '')}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.accordionsContainer}>
          {content.map((singleAccordion, index) => {
            const [isAccordionOpen, setIsAccordionOpen] = useState(false);
            const [textBoxRef, textBoxSize] = useDimensions({ liveMeasure: false });
            const [titleRef, titleSize] = useDimensions({ liveMeasure: false });
            const [pRef, pSize] = useDimensions({ liveMeasure: false });

            return (
              <div className={styles.accordionWrapper} key={index}>
                <div
                  className={
                    styles.accordion +
                    (index % 2 === (imageStartSide === 'left' ? 1 : 0)
                      ? ' ' + styles.reverse
                      : '') +
                    ' ' +
                    (isAccordionOpen ? styles.accordionOpen : '')
                  }
                >
                  <GatsbyImage
                    alt=""
                    className={styles.image}
                    image={singleAccordion.image.asset.gatsbyImageData}
                  ></GatsbyImage>
                  <div ref={textBoxRef} className={styles.textBox}>
                    <h4 ref={titleRef} className={styles.accordionTitle}>
                      {singleAccordion.title}
                    </h4>
                    <div ref={pRef} className={styles.accordionTextWrapper}>
                      <BlockContent
                        renderContainerOnSingleChild
                        blocks={singleAccordion.accordionPortableText}
                        serializers={{
                          marks: {
                            genericLink: GenericLinkSerializer,
                            pageLink: PageLinkSerializer,
                          },
                        }}
                      />
                    </div>
                    {pSize.height > textBoxSize.height - titleSize.height - TEXT_BOX_PADDING && (
                      <div className={styles.accordionLinkContainer}>
                        <span
                          className={styles.accordionLink}
                          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                        >
                          {!isAccordionOpen
                            ? t('module_accordions.see_more_label', 'See More')
                            : t('module_accordions.see_less_label', 'See Less')}{' '}
                          <FaChevronDown className={styles.linkIcon}></FaChevronDown>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {listOfLinks && (
          <div className={styles.listOfLinksContainer}>
            {listOfLinks.title && <h4 className={styles.listOfLinksTitle}>{listOfLinks.title}</h4>}
            <div className={styles.listOfLinksContent}>
              <FormControl className={styles.formControl}>
                <Select
                  className={styles.root}
                  value={url}
                  onChange={event => setUrl(event.target.value)}
                  displayEmpty
                  inputProps={{
                    'aria-label': 'Without label',
                    className: styles.select,
                  }}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                  }}
                  classes={{ icon: styles.arrow }}
                >
                  <MenuItem value="" disabled className={styles.placeholder}>
                    {listOfLinks.placeholder}
                  </MenuItem>
                  {listOfLinks.content.map((entry, i) => (
                    <MenuItem key={i} value={entry.url} className={styles.menuItem}>
                      <FaExternalLinkAlt className={styles.icon}></FaExternalLinkAlt>
                      {entry.title}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Placeholder</FormHelperText> */}
              </FormControl>
              <button
                disabled={!url}
                onClick={() => window.open(url)}
                className={styles.selectButton}
              >
                {t('module_accordions.select_button', 'Go')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionsModule;
