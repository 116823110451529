import React from 'react';
import { graphql } from 'gatsby';

import * as styles from './index.module.scss';

export const TimelineModuleFragment = graphql`
  fragment TimelineModule on SanityTimelineModule {
    title {
      ...LocaleStringFragment
    }
    entries {
      year
      text {
        ...LocaleTextFragment
      }
    }
  }
`;

export interface Entry {
  year: string;
  text: string;
}

export interface TimelineModuleProps {
  title?: string;
  entries: Array<Entry>;
}

const TimelineModule = ({ title, entries }: TimelineModuleProps): React.ReactElement => {
  const entriesPairs: Array<[Entry, Entry | undefined]> = [];
  for (let i = 1; i < entries.length; i += 2) {
    entriesPairs.push([entries[i], entries[i + 1]]);
  }
  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.timeEntriesContainer}>
        <div className={styles.timeEntry + ' ' + styles.right + ' ' + styles.firstEntry}>
          <div className={styles.yearContainer}>
            <div className={styles.line}></div>
            <div className={styles.yearBlock}>
              <span className={styles.year + ' titleParagraph'}>{entries[0].year}</span>
            </div>
          </div>
          <span className={styles.text}>{entries[0].text}</span>
        </div>
        <div className={styles.topTimelineEndingPoint}>
          <div></div>
          <div></div>
        </div>
        <div className={styles.timelineLeftLine}></div>
        <div className={styles.timelineRightLine}></div>
        <div className={styles.bottomTimelineEndingPoint}>
          <div></div>
          <div></div>
        </div>
        {entriesPairs.map((entryPair, pairIndex) => (
          <div key={pairIndex} className={styles.entryRow}>
            {entryPair.map(
              (entry, entryIndex) =>
                entry && (
                  <div
                    key={entryIndex}
                    className={
                      styles.timeEntry + ' ' + (entryIndex === 0 ? styles.left : styles.right)
                    }
                  >
                    <div className={styles.yearContainer}>
                      <div className={styles.line}></div>
                      <div className={styles.yearBlock}>
                        <span className={styles.year + ' titleParagraph'}>{entry.year}</span>
                      </div>
                    </div>
                    <span className={styles.text}>{entry.text}</span>
                  </div>
                ),
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimelineModule;
