import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { RawPortableText } from '../../../types';
import BlockContent from '@sanity/block-content-to-react';

import * as styles from './index.module.scss';

export const MultiTnIModuleFragment = graphql`
  fragment MultiTnIModule on SanityMultiTnIModule {
    title {
      ...LocaleStringFragment
    }
    imageStartSide
    content {
      title {
        ...LocaleStringFragment
      }
      portableText {
        ...LocaleSimplePortableText
      }
      image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1240
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      mobileImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 780
            height: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;

export interface MultiTnIModuleProps {
  title?: string;
  imageStartSide: string;
  content: Array<{
    title: string;
    portableText: RawPortableText;
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    mobileImage?: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  }>;
}

const MultiTnIModule = ({
  title,
  imageStartSide,
  content,
}: MultiTnIModuleProps): React.ReactElement => {
  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.blockContainer}>
          {content.map((tnIBlock, index) => (
            <div className={styles.block} key={index}>
              <div
                className={
                  styles.content +
                  (index % 2 == (imageStartSide === 'left' ? 1 : 0) ? ' ' + styles.reverse : '')
                }
              >
                {tnIBlock.mobileImage &&
                  tnIBlock.mobileImage.asset &&
                  tnIBlock.mobileImage.asset.gatsbyImageData && (
                    <GatsbyImage
                      alt=""
                      imgClassName={styles.image}
                      className={styles.mobileImageContainer}
                      image={tnIBlock.mobileImage.asset.gatsbyImageData}
                    ></GatsbyImage>
                  )}
                <GatsbyImage
                  alt=""
                  className={styles.imageContainer}
                  imgClassName={styles.image}
                  image={tnIBlock.image.asset.gatsbyImageData}
                ></GatsbyImage>
                <div className={styles.textBox}>
                  <h4 className={styles.tnITitle}>{tnIBlock.title}</h4>
                  <BlockContent renderContainerOnSingleChild blocks={tnIBlock.portableText} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiTnIModule;
