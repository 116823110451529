import React from 'react';
import { graphql } from 'gatsby';

import getYoutubeID from 'get-youtube-id';

import * as styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

export const VideoModuleFragment = graphql`
  fragment VideoModule on SanityVideoModule {
    title {
      ...LocaleStringFragment
    }
    background
    videoTitle {
      ...LocaleStringFragment
    }
    date
    videoUrl: url {
      ...LocaleStringFragment
    }
  }
`;

export interface VideoModuleProps {
  title?: string;
  background: string;
  videoTitle?: string;
  date?: string;
  videoUrl: string;
}

const VideoModule = ({
  title,
  background,
  videoTitle,
  date: dateStr,
  videoUrl,
}: VideoModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const id = getYoutubeID(videoUrl);
  if (!id) {
    throw new Error('Could not extract youtube id from url: ' + videoUrl);
  }
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  let formatedDate;
  if (dateStr) {
    const date = new Date(dateStr);
    const month = [
      t('months.short_name.january', 'jan'),
      t('months.short_name.february', 'feb'),
      t('months.short_name.march', 'mar'),
      t('months.short_name.april', 'apr'),
      t('months.short_name.may', 'may'),
      t('months.short_name.june', 'jun'),
      t('months.short_name.july', 'jul'),
      t('months.short_name.august', 'aug'),
      t('months.short_name.september', 'sep'),
      t('months.short_name.october', 'oct'),
      t('months.short_name.november', 'nov'),
      t('months.short_name.december', 'dec'),
    ][date.getMonth()].toUpperCase();
    formatedDate = month + ' ' + date.getFullYear();
  }

  return (
    <div className={styles.moduleRoot + (background === 'grey' ? ' ' + styles.withBackground : '')}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.content}>
          <iframe key="video" className={styles.iframe} src={embedUrl}></iframe>
          {formatedDate && <span className={styles.date}>{formatedDate}</span>}
          {videoTitle && <h5 className={styles.videoTitle}>{videoTitle}</h5>}
        </div>
      </div>
    </div>
  );
};

export default VideoModule;
