import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as styles from './ReadMoreLink.module.scss';
import GenericLink, { GenericLinkProps } from './GenericLink';

type ReadMoreLinkProps = GenericLinkProps & {
  noChevron?: boolean;
  noUppercase?: boolean;
};

export default function ReadMoreLink({
  noChevron,
  noUppercase,
  className,
  children,
  ...restProps
}: ReadMoreLinkProps): React.ReactElement {
  return (
    <GenericLink
      noUnderline
      noExternalUrlIcon
      className={
        styles.link +
        (noUppercase ? ' ' + styles.noUppercase : '') +
        (className ? ' ' + className : '')
      }
      {...restProps}
    >
      {children}
      {!noChevron && <FontAwesomeIcon icon="chevron-right" className={styles.iconRight} />}
    </GenericLink>
  );
}
