// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var block = "index-module--block--HUSkc";
export var blockContainer = "index-module--blockContainer--9acN7";
export var blue = "#67b9e8";
export var blueBordersLabel = "index-module--blueBordersLabel--SR1qV";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--nqz-M";
export var content = "index-module--content--wQlN4";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var infoBlock = "index-module--infoBlock--XEW2l";
export var infoIcon = "index-module--infoIcon--329Bx";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "index-module--link--PjCQN";
export var maxCharactersNotice = "index-module--maxCharactersNotice--wXQqX";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var section = "index-module--section--SrLZT";
export var stepOneBlock = "index-module--stepOneBlock--bXBDY";
export var stepThreeBlock = "index-module--stepThreeBlock--CvsAe";
export var stepTwoBlock = "index-module--stepTwoBlock--2emEi";
export var success = "#1ebc7b";
export var textArea = "index-module--textArea--luZFk";
export var title = "index-module--title--NhNvs";
export var topInfo = "index-module--topInfo--f+EFK";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";