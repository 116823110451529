// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var areaCheckbox = "index-module--areaCheckbox--Jt7rH";
export var areasContainer = "index-module--areasContainer--7xqXN";
export var block = "index-module--block--vMuAn";
export var blockContainer = "index-module--blockContainer--qY-kg";
export var blue = "#67b9e8";
export var blueBordersLabel = "index-module--blueBordersLabel--WzsIa";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--Uac3x";
export var content = "index-module--content---FHJP";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var disabled = "index-module--disabled--ASlWB";
export var error = "#f66a83";
export var formMessage = "index-module--formMessage--Ksj1E";
export var green = "#3dae2b";
export var grey = "#828382";
export var infoBlock = "index-module--infoBlock--VURE-";
export var infoIcon = "index-module--infoIcon--46qmz";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--QYlIe";
export var orange = "#fc9948";
export var otherCountryInputContainer = "index-module--otherCountryInputContainer--5rgAg";
export var paleBlue = "#64a1bb";
export var rotation = "index-module--rotation--zV+WE";
export var scale = "index-module--scale--ezISl";
export var submitButton = "index-module--submitButton--ev-Uy";
export var submitButtonDisabled = "index-module--submitButtonDisabled--yoSRj";
export var submitButtonError = "index-module--submitButtonError--qyHgk";
export var submitButtonLoadingIcon = "index-module--submitButtonLoadingIcon--jB1VL";
export var submitButtonReady = "index-module--submitButtonReady--CAGV-";
export var submitButtonSubmitted = "index-module--submitButtonSubmitted--q4dkC";
export var submitButtonSubmitting = "index-module--submitButtonSubmitting--L-QrH";
export var subscriptionBoxInfo = "index-module--subscriptionBoxInfo--wv4Er";
export var success = "#1ebc7b";
export var topInfo = "index-module--topInfo--2OG6X";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";