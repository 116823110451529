// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var contactsCardWrapper = "index-module--contactsCardWrapper--T2mLH";
export var container = "index-module--container--UJtM7";
export var continent = "index-module--continent--mdQMo";
export var continentLabel = "index-module--continentLabel--DwTYq";
export var continents = "index-module--continents--siQeM";
export var coolGrey = "#e1e2e0";
export var countries = "index-module--countries--q1CEb";
export var countryButton = "index-module--countryButton--NWarw";
export var countryContactsCardWrapper = "index-module--countryContactsCardWrapper--LMxcm";
export var countryName = "index-module--countryName--RU8HN";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var desktopMapAndContacts = "index-module--desktopMapAndContacts--ICYtU";
export var error = "#f66a83";
export var footerLink = "index-module--footerLink--LZdbq";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var mapImage = "index-module--mapImage--bgVY-";
export var mapWrapper = "index-module--mapWrapper--4zxkR";
export var mobileMapAndContacts = "index-module--mobileMapAndContacts--C8IIW";
export var moduleRoot = "index-module--moduleRoot--i8KBG";
export var orange = "#fc9948";
export var overlay = "index-module--overlay--xd5Pk";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var title = "index-module--title--T5hH9";
export var titleParagraph = "index-module--titleParagraph--db7dl";
export var warning = "#ffeda0";
export var white = "#fff";
export var withHidingCountry = "index-module--withHidingCountry--N2JxP";
export var withSelectedCountry = "index-module--withSelectedCountry--D93Rr";
export var yellow = "#fbc900";