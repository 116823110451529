export const DEFAULT_COUNTRY_BY_COUNTRY_CODE = {
  pt: 'Portugal',
  es: 'Spain',
  fr: 'France',
  it: 'Italy',
  mx: 'Mexico',
  br: 'Brazil',
  ro: 'Romania',
};

export const COUNTRIES_AND_REGIONS = [
  {
    country: 'Portugal',
    regions: [
      'Entre Douro e Minho',
      'Trás os Montes e Alto Douro',
      'Beira Litoral',
      'Beira Interior',
      'Estremadura e Ribatejo',
      'Lisboa',
      'Alto Alentejo',
      'Alentejo Litoral',
      'Algarve',
      'Açores',
      'Madeira',
    ],
  },
  {
    country: 'Spain',
    regions: [
      'Galicia',
      'Principado de Asturias',
      'Cantabria',
      'País Vasco',
      'Navarra',
      'La Rioja',
      'Castilla y León',
      'Aragón',
      'Comunidad de Madrid',
      'Castilla - La Mancha',
      'Comunidad Valenciana',
      'Aregión de Murcia',
      'Andalucía',
      'Cataluña',
      'Isla Baleares',
      'Islas Canarias',
      'Ceuta',
    ],
  },
  {
    country: 'France',
    regions: [
      'Auvergne-Rhône-Alpes',
      'Bourgogne-Franche-Comté',
      'Bretagne',
      'Centre-Val de Loire',
      'Corse',
      'Grand Est',
      'Hauts-de-France',
      'Île-de-France',
      'Normandie',
      'Nouvelle-Aquitaine',
      'Occitanie',
      'Pays de la Loire',
      "Provence-Alpes-Côte d'Azur",
    ],
  },
  {
    country: 'Italy',
    regions: [
      'Abruzzo',
      'Basilicata',
      'Calabria',
      'Campania',
      'Emilia Romagna',
      'Friuli Venezia Giulia',
      'Lazio',
      'Liguria',
      'Lombardia',
      'Marche',
      'Molise',
      'Piemonte',
      'Puglia',
      'Sardegna',
      'Sicilia',
      'Toscana',
      'Trentino-Alto Adige',
      'Umbria',
      "Valle d'Aosta",
      'Veneto',
    ],
  },
  {
    country: 'Mexico',
    regions: [
      'Baja California',
      'Sonora',
      'Baja California Sul',
      'Sinaloa',
      'Chihuahua',
      'Coahuila',
      'Durango',
      'Zacateras',
      'Nuevo Leon',
      'Nayarit',
      'Aguas Calientes',
      'Jalisco',
      'Guanajuato',
      'San Luis',
      'Queretaro',
      'Potosi',
      'Tamaylipas',
      'Hidalgo',
      'Guerrero',
      'Oaxaca',
      'Chiapas',
      'Tabasco',
      'Campeche',
      'Quintana Ron',
      'Yucatan',
      'Morelos',
      'Tlaxcala',
    ],
  },
  {
    country: 'Brazil',
    regions: [
      'Roraima',
      'Amapá',
      'Pará',
      'Amazonas',
      'Tocantins',
      'Rondônia',
      'Acre',
      'Maranhão',
      'Piauí',
      'Ceará',
      'Rio Grande do Norte',
      'Paraíba',
      'Pernambuco',
      'Alagoas',
      'Sergipe',
      'Bahia',
      'Mato Grosso',
      'Goiás',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'São Paulo',
      'Paraná',
      'Santa Catarina',
      'Rio Grande do Sul',
      'Rio de Janeiro',
      'Espírito Santo',
    ],
  },
  {
    country: 'Romania',
    regions: [
      'Maramures',
      'Crisana',
      'Transilvania',
      'Bucovina',
      'Moldova',
      'Banat',
      'Oltenia',
      'Muntenia',
      'Dobrogea',
    ],
  },
];
