import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
import { FaSeedling } from 'react-icons/fa';

import * as styles from './index.module.scss';
import { RawPortableText } from '../../../types';

import { COLORS } from '../../../constants';

export const FeaturedBoxTnIModuleFragment = graphql`
  fragment FeaturedBoxTnIModule on SanityFeaturedBoxTnIModule {
    baseColor {
      value
    }
    imageSide
    image {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1180
          height: 600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    featuredParagraphPortableText {
      ...LocaleFeaturedBoxTnIParagraphPortableText
    }
    featuredParagraphPosition
    mainText {
      ...LocaleFeaturedBoxTnIPortableText
    }
    buttonLink {
      text {
        ...LocaleStringFragment
      }
      url
    }
  }
`;

export interface FeaturedBoxTnIModuleProps {
  baseColor: {
    value: typeof COLORS.paleBlue | typeof COLORS.almostWhite;
  };
  imageSide: string;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  featuredParagraphPortableText?: RawPortableText;
  featuredParagraphPosition: 'top' | 'bottom';
  mainText: RawPortableText;
  buttonLink?: {
    text?: string;
    url?: string;
  };
}

const FeaturedBoxTnIModule = ({
  baseColor,
  imageSide,
  image,
  featuredParagraphPortableText,
  featuredParagraphPosition,
  mainText,
  buttonLink,
}: FeaturedBoxTnIModuleProps): React.ReactElement => {
  const FeaturedTextSerializer = (props: {
    node: {
      text: string;
    };
  }): React.ReactElement => {
    return (
      <div
        className={
          styles.featuredTextContainer +
          (baseColor.value === COLORS.almostWhite ? ' ' + styles.lightBackground : '')
        }
        style={{ backgroundColor: baseColor.value }}
      >
        <h4 className={styles.featuredText}>{props.node.text}</h4>
      </div>
    );
  };

  const ListSerializer = (props: any): React.ReactElement => {
    return (
      <li>
        <FaSeedling className={styles.plantIcon}></FaSeedling>
        {props.children}
      </li>
    );
  };

  const img = (
    <GatsbyImage
      className={styles.imageContainer}
      imgClassName={styles.image}
      alt=""
      image={image.asset.gatsbyImageData}
    ></GatsbyImage>
  );

  const featuredParagraph = featuredParagraphPortableText ? (
    <div
      className={
        styles.featuredParagraphPortableTextContainer +
        ' ' +
        (baseColor.value === COLORS.almostWhite ? styles.lightBackground + ' ' : '') +
        (featuredParagraphPosition === 'bottom' ? styles.bottomFeaturedParagraph : '')
      }
      style={{ backgroundColor: baseColor.value }}
    >
      <BlockContent
        className={styles.paragraph + ' titleParagraph'}
        renderContainerOnSingleChild
        blocks={featuredParagraphPortableText}
        serializers={{
          types: {
            featuredBoxTnIFeaturedText: FeaturedTextSerializer,
          },
          listItem: ListSerializer,
        }}
      />
    </div>
  ) : (
    ''
  );

  const blockContent = (
    <BlockContent
      className={styles.block + ' titleParagraph'}
      renderContainerOnSingleChild
      blocks={mainText}
      serializers={{
        types: {
          featuredBoxTnIFeaturedText: FeaturedTextSerializer,
        },
      }}
    />
  );

  const text = (
    <div className={styles.textModulePart}>
      {featuredParagraphPosition === 'top' && featuredParagraph}
      {blockContent}
      {featuredParagraphPosition === 'bottom' && featuredParagraph}

      {buttonLink && buttonLink.text && buttonLink.url && (
        <button className={styles.button} onClick={() => window.open(buttonLink.url)}>
          {buttonLink.text}
        </button>
      )}
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.content + (imageSide === 'right' ? ' ' + styles.reverse : '')}>
        {img}
        {text}
      </div>
    </div>
  );
};

export default FeaturedBoxTnIModule;
