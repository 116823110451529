// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var disabled = "forms-module--disabled---nvYI";
export var error = "#f66a83";
export var formErrors = "forms-module--formErrors--KizJT";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var rotation = "forms-module--rotation--FBnb9";
export var submitButton = "forms-module--submitButton--avUrF";
export var submitButtonDisabled = "forms-module--submitButtonDisabled--p7qn8";
export var submitButtonFailed = "forms-module--submitButtonFailed--mMDk0";
export var submitButtonLoadingIcon = "forms-module--submitButtonLoadingIcon--mJ3II";
export var submitButtonReady = "forms-module--submitButtonReady--o2Zk1";
export var submitButtonSubmitted = "forms-module--submitButtonSubmitted--ZlM+R";
export var submitButtonSubmitting = "forms-module--submitButtonSubmitting--KbfRm";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";