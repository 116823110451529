import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';

import * as styles from './index.module.scss';

import { COLORS } from '../../../constants';
import { RawPortableText } from '../../../types';
import { GenericLinkSerializer, PageLinkSerializer } from '../../../serializers';
import ReadMoreLink from '../../ReadMoreLink';
import { getUrlFromVersatileLink } from '../../../utils/utils';
import { VersatileLinkMaybeEmpty } from '../../../graphql-fragments/versatileLink';
import { useTranslation } from 'react-i18next';

export const MultiCardModuleFragment = graphql`
  fragment MultiCardModule on SanityMultiCardModule {
    title {
      ...LocaleStringFragment
    }
    baseColor {
      value
    }
    multiCard {
      image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            height: 520
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      cardTitle {
        ...LocaleStringFragment
      }
      portableText {
        ...LocaleSimplePortableTextWithLinks
      }
      cardVersatileLink {
        ...VersatileLink
      }
    }
  }
`;

export interface MultiCardModuleProps {
  title?: string;
  baseColor: {
    value: typeof COLORS.paleBlue;
  };
  multiCard: Array<{
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    cardTitle: string;
    portableText: RawPortableText;
    cardVersatileLink?: VersatileLinkMaybeEmpty;
  }>;
}

const MultiCardModule = ({
  title,
  baseColor,
  multiCard,
}: MultiCardModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const cardsPerRowClass = {
    3: styles.cardsPerRow3,
    4: styles.cardsPerRow4,
    5: styles.cardsPerRow5,
    6: styles.cardsPerRow6,
  }[multiCard.length > 6 ? 6 : multiCard.length];

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.content}>
          {multiCard.map((card, index) => (
            <div key={index} className={styles.card + ' ' + cardsPerRowClass}>
              <div className={styles.imageCardWrapper}>
                <GatsbyImage
                  alt=""
                  className={styles.imageCardContainer}
                  imgClassName={styles.image}
                  image={card.image.asset.gatsbyImageData}
                ></GatsbyImage>
                <div className={styles.titleBar} style={{ backgroundColor: baseColor.value }}>
                  <h4 className={styles.cardTitle}>{card.cardTitle}</h4>
                </div>
              </div>
              <div className={styles.contentContainer}>
                <BlockContent
                  className={styles.paragraph}
                  renderContainerOnSingleChild
                  blocks={card.portableText}
                  serializers={{
                    marks: {
                      genericLink: GenericLinkSerializer,
                      pageLink: PageLinkSerializer,
                    },
                  }}
                />
                {(card.cardVersatileLink?.url || card.cardVersatileLink?.pageReference) && (
                  <ReadMoreLink
                    className={styles.link}
                    to={getUrlFromVersatileLink(card.cardVersatileLink)}
                  >
                    {t('module_multi_card.see_more_link', 'See more')}
                  </ReadMoreLink>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiCardModule;
