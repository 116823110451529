import React from 'react';
import { FaRegCalendar, FaMapMarkerAlt, FaGlobe, FaExternalLinkAlt } from 'react-icons/fa';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import GenericLink from './GenericLink';
import { useTranslation } from 'react-i18next';

import * as styles from './EventCard.module.scss';

export interface EventCardProps {
  title: string;
  description?: string;
  url?: string;
  language?: string;
  location: string;
  image?: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
  startDate: string;
  endDate?: string;
  className?: string;
}

const EventCard = ({
  title,
  description,
  url,
  language,
  location,
  image,
  startDate,
  endDate,
  className,
}: EventCardProps): React.ReactElement => {
  const { t } = useTranslation();

  const date =
    endDate && startDate !== endDate
      ? startDate.split('-')[1] !== endDate.split('-')[1]
        ? startDate.split('-')[2] +
          '-' +
          startDate.split('-')[1] +
          ' ' +
          t('event_card.date_preposition', 'to') +
          ' ' +
          endDate.split('-').reverse().join('-')
        : startDate.split('-')[2] +
          ' ' +
          t('event_card.date_preposition', 'to') +
          ' ' +
          endDate.split('-').reverse().join('-')
      : startDate.split('-').reverse().join('-');

  return (
    <div className={styles.card + ' ' + className}>
      <div className={styles.leftContainer}>
        <div className={styles.dateContainer}>
          <FaRegCalendar className={styles.dateIcon}></FaRegCalendar>
          <span className={styles.date + ' titleParagraph'}>{date}</span>
        </div>
        {image && image.asset && (
          <GatsbyImage
            alt={title}
            className={styles.imageContainer}
            imgClassName={styles.image}
            image={image.asset.gatsbyImageData}
          ></GatsbyImage>
        )}
      </div>
      <div className={styles.rightContainer}>
        <h4 className={styles.title}>{title}</h4>
        {description && <p className={styles.description}>{description}</p>}

        <div className={styles.infoAndImageContainer}>
          {image && image.asset && (
            <div className={styles.mobileImageContainer}>
              <img className={styles.image} src={image.asset.url}></img>
            </div>
          )}

          <div className={styles.infoContainer}>
            {location && (
              <div className={styles.info}>
                <FaMapMarkerAlt className={styles.eventInfoIcon}></FaMapMarkerAlt>
                <span className={styles.location}>{location}</span>
              </div>
            )}

            {language && (
              <div className={styles.info}>
                <FaGlobe className={styles.eventInfoIcon}></FaGlobe>
                {language && <span className={styles.language}>{language}</span>}
              </div>
            )}

            {url && (
              <div className={styles.info + ' ' + styles.externalLinkContainer}>
                <FaExternalLinkAlt
                  className={styles.eventInfoIcon + ' ' + styles.externalLinkIcon}
                ></FaExternalLinkAlt>
                <GenericLink noStyle className={'titleParagraph ' + styles.link} to={url}>
                  {t('event_card.link', 'Visit event page')}
                </GenericLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
