import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import * as styles from './index.module.scss';

export const LinksDropdownLinksDropdownModuleFragment = graphql`
  fragment LinksDropdownModule on SanityLinksDropdownModule {
    title {
      ...LocaleStringFragment
    }
    text {
      ...LocaleTextFragment
    }
    dropdownPlaceholder {
      ...LocaleStringFragment
    }
    dropdownLinks {
      name {
        ...LocaleStringFragment
      }
      url
    }
  }
`;

export interface LinksDropdownModuleProps {
  title: string;
  text: string;
  dropdownPlaceholder: string;
  dropdownLinks: Array<{
    name: string;
    url: string;
  }>;
}

const LinksDropdownModule = ({
  title,
  text,
  dropdownPlaceholder,
  dropdownLinks,
}: LinksDropdownModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const [url, setUrl] = useState('');

  return (
    <div className={styles.moduleRoot}>
      {/* {title && <h3 className={styles.title}>{title}</h3>} */}
      <div className={styles.container}>
        <h4 className={styles.paragraph}>{text}</h4>

        <div className={styles.dropDownList}>
          <FormControl className={styles.formControl}>
            <Select
              className={styles.root}
              value={url}
              onChange={event => setUrl(event.target.value)}
              displayEmpty
              inputProps={{
                'aria-label': 'Without label',
                className: styles.select,
              }}
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                getContentAnchorEl: null,
              }}
              classes={{ icon: styles.arrow }}
            >
              <MenuItem value="" disabled className={styles.placeholder}>
                {dropdownPlaceholder}
              </MenuItem>
              {dropdownLinks.map((link, i) => (
                <MenuItem key={i} value={link.url} className={styles.menuItem}>
                  <FaExternalLinkAlt className={styles.icon}></FaExternalLinkAlt>
                  {link.name}
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText>Placeholder</FormHelperText> */}
          </FormControl>
          <button disabled={!url} onClick={() => window.open(url)} className={styles.selectButton}>
            {t('module_links_dropdown.go_button', 'Go')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinksDropdownModule;
