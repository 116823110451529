import React from 'react';
import { graphql } from 'gatsby';
import { RawPortableText } from '../../../types';
import BlockContent from '@sanity/block-content-to-react';

import FlowTriangleSvg from '../../../assets/flowTriangle.inline.svg';
import FlowTriangleRightSvg from '../../../assets/flowTriangleRight.inline.svg';

import * as styles from './index.module.scss';

export const FlowFragment = graphql`
  fragment FlowModule on SanityFlowModule {
    title {
      ...LocaleStringFragment
    }
    entries {
      entryTitle {
        ...LocaleStringFragment
      }
      entryPortableText {
        ...LocaleSimplePortableText
      }
    }
  }
`;

export interface FlowModuleProps {
  title?: string;
  entries: Array<{
    entryTitle: string;
    entryPortableText: RawPortableText;
  }>;
}

const INITIAL_OPACITY = 0.6;

const FlowModule = ({ title, entries }: FlowModuleProps): React.ReactElement => {
  const opacityIncrease = (1 - INITIAL_OPACITY) / (entries.length - 1);
  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.content}>
        {entries.map((entry, index) => (
          <div key={index} className={styles.entry}>
            <div
              className={styles.entryTitleContainer}
              style={{ opacity: INITIAL_OPACITY + opacityIncrease * index }}
            >
              <FlowTriangleSvg className={styles.firstTriangle}></FlowTriangleSvg>
              <span className={styles.entryTitle + ' titleParagraph'}>{entry.entryTitle}</span>
              <FlowTriangleRightSvg className={styles.lastTriangle}></FlowTriangleRightSvg>
            </div>
            <BlockContent
              className={styles.entryText}
              renderContainerOnSingleChild
              blocks={entry.entryPortableText}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlowModule;
