// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var bottomButton = "HeroSlide-module--bottomButton--Jxa1K";
export var button = "HeroSlide-module--button--krwwl";
export var buttonWithBackground = "HeroSlide-module--buttonWithBackground--sqdC+";
export var container = "HeroSlide-module--container--38zx-";
export var content = "HeroSlide-module--content--5GXLj";
export var contentWithDefaultButton = "HeroSlide-module--contentWithDefaultButton--TSro1";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var iframe = "HeroSlide-module--iframe--emDDk";
export var image = "HeroSlide-module--image--AEbWj";
export var imageContainer = "HeroSlide-module--imageContainer--BF9je";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "HeroSlide-module--link--pm50f";
export var linkIcon = "HeroSlide-module--linkIcon--Ot85r";
export var mobileImageContainer = "HeroSlide-module--mobileImageContainer--LjSvT";
export var orange = "#fc9948";
export var overlay = "HeroSlide-module--overlay--bov7J";
export var paleBlue = "#64a1bb";
export var paragraph = "HeroSlide-module--paragraph--brQk4";
export var playIcon = "HeroSlide-module--playIcon--6tL2x";
export var success = "#1ebc7b";
export var title = "HeroSlide-module--title--CVvng";
export var topButton = "HeroSlide-module--topButton--68e+H";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";