import React, { useState } from 'react';
import { graphql } from 'gatsby';
import getYoutubeID from 'get-youtube-id';
import { FaPlayCircle } from 'react-icons/fa';
import * as styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const MAX_NUMBER_OF_VIDEOS_WITHOUT_SCROLL = 5;

export const MultiVideoModuleFragment = graphql`
  fragment MultiVideoModule on SanityMultiVideoModule {
    title {
      ...LocaleStringFragment
    }
    background
    listTitle {
      ...LocaleStringFragment
    }
    videos {
      title {
        ...LocaleStringFragment
      }
      url {
        ...LocaleStringFragment
      }
      duration_min {
        ...LocaleNumberFragment
      }
    }
  }
`;

interface Video {
  title: string;
  url: string;
  duration_min: number;
}

export interface MultiVideoModuleProps {
  title?: string;
  background?: string;
  listTitle: string;
  videos: Array<Video>;
}

const MultiVideoModule = ({
  title,
  background,
  listTitle,
  videos,
}: MultiVideoModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.moduleRoot + (background === 'grey' ? ' ' + styles.withBackground : '')}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.videos}>
          <div className={styles.mainVideo}>
            {videos.length > 0 && (
              <iframe
                key="video"
                className={styles.iframe}
                src={`https://www.youtube.com/embed/${getYoutubeID(videos[activeIndex].url)}`}
              ></iframe>
            )}
          </div>
          <div className={styles.videoListContainer}>
            <h4 className={styles.videoListTitle}>{listTitle}</h4>
            <div
              className={
                styles.videoList +
                (videos.length > MAX_NUMBER_OF_VIDEOS_WITHOUT_SCROLL
                  ? ' ' + styles.scrollableBox
                  : '')
              }
            >
              {videos.length > 0 &&
                videos.map((video, i) => (
                  <button
                    key={i}
                    className={styles.videoCard + (activeIndex === i ? ' ' + styles.active : '')}
                    onClick={() => setActiveIndex(i)}
                  >
                    <div className={styles.thumbnailContainer}>
                      <img
                        src={`https://img.youtube.com/vi/${getYoutubeID(video.url)}/0.jpg`}
                      ></img>
                    </div>
                    <div className={styles.cardContent}>
                      <span className={styles.videoTitle}>{video.title}</span>
                      <div className={styles.videoDuration}>
                        <FaPlayCircle className={styles.icon}></FaPlayCircle>
                        {video.duration_min} {' ' + t('module_multi_video.minutes', 'min')}
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiVideoModule;
