import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { RawPortableText } from '../../../types';
import BlockContent from '@sanity/block-content-to-react';
import { FaSeedling } from 'react-icons/fa';
import * as styles from './index.module.scss';

export const SingleBoxBulletPointsModuleFragment = graphql`
  fragment SingleBoxBulletPointsModule on SanitySingleBoxBulletPointsModule {
    title {
      ...LocaleStringFragment
    }
    introPortableText {
      ...LocaleSimplePortableText
    }
    introTextStyle
    section {
      image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 780
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      content {
        singleTitle {
          ...LocaleStringFragment
        }
        bulletPoints {
          ...LocaleStringFragment
        }
      }
    }
    outroText {
      ...LocaleTextFragment
    }
  }
`;

export interface SingleBoxBulletPointsModuleProps {
  title?: string;
  introPortableText?: RawPortableText;
  introTextStyle: string;
  section: {
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    content: Array<{
      singleTitle?: string;
      bulletPoints: Array<string>;
    }>;
  };
  outroText?: string;
}

const SingleBoxBulletPointsModule = ({
  title,
  introPortableText,
  introTextStyle,
  section,
  outroText,
}: SingleBoxBulletPointsModuleProps): React.ReactElement => {
  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}

        {introPortableText &&
          (introTextStyle === 'title' ? (
            <>
              <BlockContent
                className={styles.headingIntroText + ' ' + styles.introText}
                renderContainerOnSingleChild
                blocks={introPortableText}
              />
            </>
          ) : (
            <BlockContent
              className={styles.paragraphIntroText + ' ' + styles.introText}
              renderContainerOnSingleChild
              blocks={introPortableText}
            />
          ))}
        <div className={styles.bulletPointBox}>
          <GatsbyImage
            alt=""
            className={styles.imageContainer}
            imgClassName={styles.image}
            image={section.image.asset.gatsbyImageData}
          ></GatsbyImage>
          <div className={styles.textBlock}>
            {section.content.map((content, index) => (
              <div key={index} className={styles.bulletPointsSection}>
                {content.singleTitle && (
                  <span className={styles.bulletPointsSingleTitle}>{content.singleTitle}</span>
                )}
                <ul className={styles.bulletPointsList}>
                  {content.bulletPoints.map((singleBulletPoint, i) => {
                    return (
                      <li key={i} className={styles.singleBulletPoint}>
                        <FaSeedling className={styles.bulletIcon}></FaSeedling>
                        {singleBulletPoint}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
            {outroText && <p className={styles.outroText}>{outroText}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBoxBulletPointsModule;
