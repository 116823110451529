import React from 'react';
import { graphql } from 'gatsby';

import { DISABLED_HERO, DISABLED_MODULES } from '../constants';
import * as styles from './Page.module.scss';

import Layout from '../components/Layout';
import ImageModule, { ImageModuleProps } from '../components/modules/ImageModule';
import ParagraphModule, { ParagraphModuleProps } from '../components/modules/ParagraphModule';
import DoubleTnIModule, { DoubleTnIModuleProps } from '../components/modules/DoubleTnIModule';
import NumbersModule, { NumbersModuleProps } from '../components/modules/NumbersModule';
import FeaturedBoxTnIModule, {
  FeaturedBoxTnIModuleProps,
} from '../components/modules/FeaturedBoxTnIModule';
import QuoteModule, { QuoteModuleProps } from '../components/modules/QuoteModule';
import VideoModule, { VideoModuleProps } from '../components/modules/VideoModule';
import MultiVideoModule, { MultiVideoModuleProps } from '../components/modules/MultiVideoModule';
import MultiCardModule, { MultiCardModuleProps } from '../components/modules/MultiCardModule';
import WorldwideModule, { WorldwideModuleProps } from '../components/modules/WorldwideModule';
import TimelineModule, { TimelineModuleProps } from '../components/modules/TimelineModule';
import MultiTnIModule, { MultiTnIModuleProps } from '../components/modules/MultiTnIModule';
import MultiBoxBulletPointsModule, {
  MultiBoxBulletPointsModuleProps,
} from '../components/modules/MultiBoxBulletPointsModule';
import SingleBoxBulletPointsModule, {
  SingleBoxBulletPointsModuleProps,
} from '../components/modules/SingleBoxBulletPointsModule';
import AccordionsModule, { AccordionsModuleProps } from '../components/modules/AccordionsModule';
import PyramidModule, { PyramidModuleProps } from '../components/modules/PyramidModule';
import FlowModule, { FlowModuleProps } from '../components/modules/FlowModule';
import ContactsModule, { ContactsModuleProps } from '../components/modules/ContactsModule';
import RichTextModule, { RichTextModuleProps } from '../components/modules/RichTextModule';
import GoogleMapModule, { GoogleMapModuleProps } from '../components/modules/GoogleMapModule';
import ContactFormModule, { ContactFormModuleProps } from '../components/modules/ContactFormModule';
import GroupedNumbersModule, {
  GroupedNumbersModuleProps,
} from '../components/modules/GroupedNumbersModule';
import MultiTnIconModule, { MultiTnIconModuleProps } from '../components/modules/MultiTnIconModule';
import LinksDropdownModule, {
  LinksDropdownModuleProps,
} from '../components/modules/LinksDropdownModule';
import NewsModule, { NewsModuleProps } from '../components/modules/NewsModule';
import NewsletterModule, { NewsletterModuleProps } from '../components/modules/NewsletterModule';
import EventModule, { EventModuleProps } from '../components/modules/EventModule';
import GroupedContactsModule, {
  GroupedContactsModuleProps,
} from '../components/modules/GroupedContactsModule';
import ResourceFilesModule, {
  ResourceFilesModuleProps,
} from '../components/modules/ResourceFilesModule';
import AssetsModule, { AssetsModuleProps } from '../components/modules/AssetsModule';
import TabbedCardListModule, {
  TabbedCardListModuleProps,
} from '../components/modules/TabbedCardListModule';
import ButtonModule, { ButtonModuleProps } from '../components/modules/ButtonModule';

import HeroSlider, { HeroSlide } from '../components/HeroSlider';
import { slugify } from '../utils/utils';
import { withI18n } from '../utils/hocs';
import { useLocalization } from '../utils/hooks';
import PageSEO from '../components/PageSEO';
import { LocalizedSEO } from '../graphql-fragments/seo';

export const query = graphql`
  query GetPageById($id: String!) {
    sanityPage(id: { eq: $id }) {
      title {
        ...LocaleStringFragment
      }
      slug {
        current
      }
      id
      openGraph {
        ...SEO
      }
      sections {
        name {
          ...LocaleStringFragment
        }
        modules {
          __typename
          ...ImageModule
          ... on SanityImageModule {
            disabled
          }
          ...ParagraphModule
          ... on SanityParagraphModule {
            disabled
          }
          ...DoubleTnIModule
          ... on SanityDoubleTnIModule {
            disabled
          }
          ...NumbersModule
          ... on SanityNumbersModule {
            disabled
          }
          ...FeaturedBoxTnIModule
          ... on SanityFeaturedBoxTnIModule {
            disabled
          }
          ...QuoteModule
          ... on SanityQuoteModule {
            disabled
          }
          ...VideoModule
          ... on SanityVideoModule {
            disabled
          }
          ...MultiVideoModule
          ... on SanityMultiVideoModule {
            disabled
          }
          ...MultiCardModule
          ... on SanityMultiCardModule {
            disabled
          }
          ...WorldwideModule
          ... on SanityWorldwideModule {
            disabled
          }
          ...TimelineModule
          ... on SanityTimelineModule {
            disabled
          }
          ...MultiTnIModule
          ... on SanityMultiTnIModule {
            disabled
          }
          ...MultiBoxBulletPointsModule
          ... on SanityMultiBoxBulletPointsModule {
            disabled
          }
          ...SingleBoxBulletPointsModule
          ... on SanitySingleBoxBulletPointsModule {
            disabled
          }
          ...AccordionsModule
          ... on SanityAccordionsModule {
            disabled
          }
          ...PyramidModule
          ... on SanityPyramidModule {
            disabled
          }
          ...FlowModule
          ... on SanityFlowModule {
            disabled
          }
          ...ContactsModule
          ... on SanityContactsModule {
            disabled
          }
          ...RichTextModule
          ... on SanityRichTextModule {
            disabled
          }
          ...GoogleMapModule
          ... on SanityGoogleMapModule {
            disabled
          }
          ...ContactFormModule
          ... on SanityContactFormModule {
            disabled
          }
          ...GroupedNumbersModule
          ... on SanityGroupedNumbersModule {
            disabled
          }
          ...MultiTnIconModule
          ... on SanityMultiTnIconModule {
            disabled
          }
          ...LinksDropdownModule
          ... on SanityLinksDropdownModule {
            disabled
          }
          ...NewsModule
          ... on SanityNewsModule {
            disabled
          }
          ...NewsletterModule
          ... on SanityNewsletterModule {
            disabled
          }
          ...EventModule
          ... on SanityEventsModule {
            disabled
          }
          ...GroupedContactsModule
          ... on SanityGroupedContactsModule {
            disabled
          }
          ...ResourceFilesModule
          ... on SanityResourceFilesModule {
            disabled
          }
          ...AssetsModule
          ... on SanityAssetsModule {
            disabled
          }
          ...TabbedCardListModule
          ... on SanityTabbedCardListModule {
            disabled
          }
          ...ButtonModule
          ... on SanityButtonModule {
            disabled
          }
        }
      }
      hideSectionsMenu
      hero {
        ...HeroSlider
      }
    }
  }
`;

interface LocalizedQueryData {
  sanityPage: {
    title: string;
    slug: {
      current: string;
    };
    id: string;
    openGraph: LocalizedSEO;
    sections: Array<{
      name: string;
      nameEn: string;
      modules: Array<
        | (ImageModuleProps & { __typename: 'SanityImageModule' } & {
            disabled: boolean | null;
          })
        | (ParagraphModuleProps & { __typename: 'SanityParagraphModule' } & {
            disabled: boolean | null;
          })
        | (DoubleTnIModuleProps & { __typename: 'SanityDoubleTnIModule' } & {
            disabled: boolean | null;
          })
        | (NumbersModuleProps & { __typename: 'SanityNumbersModule' } & {
            disabled: boolean | null;
          })
        | (FeaturedBoxTnIModuleProps & {
            __typename: 'SanityFeaturedBoxTnIModule';
          } & {
            disabled: boolean | null;
          })
        | (QuoteModuleProps & { __typename: 'SanityQuoteModule' } & {
            disabled: boolean | null;
          })
        | (VideoModuleProps & { __typename: 'SanityVideoModule' } & {
            disabled: boolean | null;
          })
        | (MultiVideoModuleProps & { __typename: 'SanityMultiVideoModule' } & {
            disabled: boolean | null;
          })
        | (MultiCardModuleProps & { __typename: 'SanityMultiCardModule' } & {
            disabled: boolean | null;
          })
        | (WorldwideModuleProps & { __typename: 'SanityWorldwideModule' } & {
            disabled: boolean | null;
          })
        | (TimelineModuleProps & { __typename: 'SanityTimelineModule' } & {
            disabled: boolean | null;
          })
        | (MultiTnIModuleProps & { __typename: 'SanityMultiTnIModule' } & {
            disabled: boolean | null;
          })
        | (MultiBoxBulletPointsModuleProps & {
            __typename: 'SanityMultiBoxBulletPointsModule';
          } & {
            disabled: boolean | null;
          })
        | (SingleBoxBulletPointsModuleProps & {
            __typename: 'SanitySingleBoxBulletPointsModule';
          } & {
            disabled: boolean | null;
          })
        | (AccordionsModuleProps & { __typename: 'SanityAccordionsModule' } & {
            disabled: boolean | null;
          })
        | (PyramidModuleProps & { __typename: 'SanityPyramidModule' } & {
            disabled: boolean | null;
          })
        | (FlowModuleProps & { __typename: 'SanityFlowModule' } & {
            disabled: boolean | null;
          })
        | (ContactsModuleProps & { __typename: 'SanityContactsModule' } & {
            disabled: boolean | null;
          })
        | (RichTextModuleProps & { __typename: 'SanityRichTextModule' } & {
            disabled: boolean | null;
          })
        | (GoogleMapModuleProps & { __typename: 'SanityGoogleMapModule' } & {
            disabled: boolean | null;
          })
        | (ContactFormModuleProps & { __typename: 'SanityContactFormModule' } & {
            disabled: boolean | null;
          })
        | (GroupedNumbersModuleProps & { __typename: 'SanityGroupedNumbersModule' } & {
            disabled: boolean | null;
          })
        | (MultiTnIconModuleProps & { __typename: 'SanityMultiTnIconModule' } & {
            disabled: boolean | null;
          })
        | (LinksDropdownModuleProps & { __typename: 'SanityLinksDropdownModule' } & {
            disabled: boolean | null;
          })
        | (NewsModuleProps & { __typename: 'SanityNewsModule' } & {
            disabled: boolean | null;
          })
        | (NewsletterModuleProps & { __typename: 'SanityNewsletterModule' } & {
            disabled: boolean | null;
          })
        | (EventModuleProps & { __typename: 'SanityEventsModule' } & {
            disabled: boolean | null;
          })
        | (GroupedContactsModuleProps & { __typename: 'SanityGroupedContactsModule' } & {
            disabled: boolean | null;
          })
        | (ResourceFilesModuleProps & { __typename: 'SanityResourceFilesModule' } & {
            disabled: boolean | null;
          })
        | (AssetsModuleProps & { __typename: 'SanityAssetsModule' } & {
            disabled: boolean | null;
          })
        | (TabbedCardListModuleProps & { __typename: 'SanityTabbedCardListModule' } & {
            disabled: boolean | null;
          })
        | (ButtonModuleProps & { __typename: 'SanityButtonModule' } & {
            disabled: boolean | null;
          })
      >;
    }>;
    hideSectionsMenu: boolean;
    hero?: {
      slides: Array<HeroSlide>;
    };
  };
}

interface PageProps {
  data: any;
}

const Page = ({ data }: PageProps): React.ReactElement => {
  data.sanityPage = {
    ...data.sanityPage,
    sections: data.sanityPage.sections.map(section => ({
      ...section,
      nameEn: section.name.en,
    })),
  };
  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const { sanityPage: page } = localizedData;

  const { openGraph } = localizedData.sanityPage;

  const isHomepage = page.slug.current === 'homepage';
  return (
    <Layout
      transparentHeader={
        !!(page.hero && page.hero.slides && page.hero.slides.length > 0 && !DISABLED_HERO)
      }
    >
      <PageSEO
        pageSEO={{
          ...openGraph,
          title: isHomepage ? '' : openGraph?.title || page.title,
        }}
      />
      {page.hero && page.hero.slides && page.hero.slides.length > 0 && !DISABLED_HERO && (
        <HeroSlider {...page.hero}></HeroSlider>
      )}
      {page.sections.map(({ name, nameEn, modules }, sectionIndex) => (
        <section key={'section-' + sectionIndex} className={styles.sectionContainer}>
          <span id={slugify(nameEn)} className={styles.anchor}></span>
          <div
            className={
              styles.sectionTitleContainer + ' ' + (isHomepage ? styles.sectionTitleNoBars : '')
            }
          >
            <div className={styles.border}></div>
            <h2>{name}</h2>
            <div className={styles.border}></div>
          </div>
          {modules
            .filter(module => !module.disabled && !DISABLED_MODULES.includes(module.__typename))
            .map((module, moduleIndex) => {
              const moduleKey = 'module-' + sectionIndex + '-' + moduleIndex;
              if (module.__typename === 'SanityImageModule') {
                return <ImageModule key={moduleKey} {...module}></ImageModule>;
              }
              if (module.__typename === 'SanityParagraphModule') {
                return <ParagraphModule key={moduleKey} {...module}></ParagraphModule>;
              }
              if (module.__typename === 'SanityDoubleTnIModule') {
                return <DoubleTnIModule key={moduleKey} {...module}></DoubleTnIModule>;
              }
              if (module.__typename === 'SanityNumbersModule') {
                return <NumbersModule key={moduleKey} {...module}></NumbersModule>;
              }
              if (module.__typename === 'SanityFeaturedBoxTnIModule') {
                return <FeaturedBoxTnIModule key={moduleKey} {...module}></FeaturedBoxTnIModule>;
              }
              if (module.__typename === 'SanityQuoteModule') {
                return <QuoteModule key={moduleKey} {...module}></QuoteModule>;
              }
              if (module.__typename === 'SanityVideoModule') {
                return <VideoModule key={moduleKey} {...module}></VideoModule>;
              }
              if (module.__typename === 'SanityMultiVideoModule') {
                return <MultiVideoModule key={moduleKey} {...module}></MultiVideoModule>;
              }
              if (module.__typename === 'SanityMultiCardModule') {
                return <MultiCardModule key={moduleKey} {...module}></MultiCardModule>;
              }
              if (module.__typename === 'SanityWorldwideModule') {
                return <WorldwideModule key={moduleKey} {...module}></WorldwideModule>;
              }
              if (module.__typename === 'SanityTimelineModule') {
                return <TimelineModule key={moduleKey} {...module}></TimelineModule>;
              }
              if (module.__typename === 'SanityMultiTnIModule') {
                return <MultiTnIModule key={moduleKey} {...module}></MultiTnIModule>;
              }
              if (module.__typename === 'SanityMultiBoxBulletPointsModule') {
                return (
                  <MultiBoxBulletPointsModule
                    key={moduleKey}
                    {...module}
                  ></MultiBoxBulletPointsModule>
                );
              }
              if (module.__typename === 'SanitySingleBoxBulletPointsModule') {
                return (
                  <SingleBoxBulletPointsModule
                    key={moduleKey}
                    {...module}
                  ></SingleBoxBulletPointsModule>
                );
              }
              if (module.__typename === 'SanityAccordionsModule') {
                return <AccordionsModule key={moduleKey} {...module}></AccordionsModule>;
              }
              if (module.__typename === 'SanityPyramidModule') {
                return <PyramidModule key={moduleKey} {...module}></PyramidModule>;
              }
              if (module.__typename === 'SanityFlowModule') {
                return <FlowModule key={moduleKey} {...module}></FlowModule>;
              }
              if (module.__typename === 'SanityContactsModule') {
                return <ContactsModule key={moduleKey} {...module}></ContactsModule>;
              }
              if (module.__typename === 'SanityRichTextModule') {
                return <RichTextModule key={moduleKey} {...module}></RichTextModule>;
              }
              if (module.__typename === 'SanityGoogleMapModule') {
                return <GoogleMapModule key={moduleKey} {...module}></GoogleMapModule>;
              }
              if (module.__typename === 'SanityContactFormModule') {
                return <ContactFormModule key={moduleKey} {...module}></ContactFormModule>;
              }
              if (module.__typename === 'SanityGroupedNumbersModule') {
                return <GroupedNumbersModule key={moduleKey} {...module}></GroupedNumbersModule>;
              }
              if (module.__typename === 'SanityMultiTnIconModule') {
                return <MultiTnIconModule key={moduleKey} {...module}></MultiTnIconModule>;
              }
              if (module.__typename === 'SanityLinksDropdownModule') {
                return <LinksDropdownModule key={moduleKey} {...module}></LinksDropdownModule>;
              }
              if (module.__typename === 'SanityNewsModule') {
                return <NewsModule key={moduleKey} {...module}></NewsModule>;
              }
              if (module.__typename === 'SanityNewsletterModule') {
                return <NewsletterModule key={moduleKey} {...module}></NewsletterModule>;
              }
              if (module.__typename === 'SanityEventsModule') {
                return <EventModule key={moduleKey} {...module}></EventModule>;
              }
              if (module.__typename === 'SanityGroupedContactsModule') {
                return <GroupedContactsModule key={moduleKey} {...module}></GroupedContactsModule>;
              }
              if (module.__typename === 'SanityResourceFilesModule') {
                return <ResourceFilesModule key={moduleKey} {...module}></ResourceFilesModule>;
              }
              if (module.__typename === 'SanityAssetsModule') {
                return <AssetsModule key={moduleKey} {...module}></AssetsModule>;
              }
              if (module.__typename === 'SanityTabbedCardListModule') {
                return <TabbedCardListModule key={moduleKey} {...module}></TabbedCardListModule>;
              }
              if (module.__typename === 'SanityButtonModule') {
                return <ButtonModule key={moduleKey} {...module}></ButtonModule>;
              }
              // @ts-ignore
              throw new Error('Got unexpected typename: ' + module.__typename);
            })
            .map((moduleEl, index) => (
              <div key={index} className={styles.moduleContainer}>
                {moduleEl}
              </div>
            ))}
        </section>
      ))}
    </Layout>
  );
};

export default withI18n(Page);
