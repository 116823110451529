import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './index.module.scss';
import QuoteIcon from '../../../assets/images/quote.inline.svg';

import { COLORS } from '../../../constants';

export const QuoteModuleFragment = graphql`
  fragment QuoteModule on SanityQuoteModule {
    title: label {
      ...LocaleStringFragment
    }
    background
    quoteColor {
      value
    }
    width
    author {
      ...LocaleStringFragment
    }
    quote {
      ...LocaleTextFragment
    }
    imageSide
    image {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 680
          height: 740
          placeholder: BLURRED
          formats: [JPG, WEBP, AVIF]
        )
      }
    }
    facePhoto
  }
`;

export interface QuoteModuleProps {
  title?: string;
  background: string;
  quoteColor: {
    value: typeof COLORS.blue | typeof COLORS.paleBlue | typeof COLORS.orange | typeof COLORS.green;
  };
  width: string;
  author: string;
  quote: string;
  imageSide: string;
  image?: {
    asset?: {
      gatsbyImageData?: IGatsbyImageData;
    };
  };
  facePhoto: boolean | null;
}

const QuoteModule = ({
  title,
  background,
  quoteColor,
  width,
  author,
  quote,
  imageSide,
  image,
  facePhoto,
}: QuoteModuleProps): React.ReactElement => {
  const iconColor = background === 'grey' ? COLORS.grey : quoteColor.value;

  // HACK For some reason, when the image is loaded in Sanity in TIF format,
  // it doesn't show correctly in the site. The "solution" is to replace the format
  // inside the image source data from image/tif to image/jpeg.
  const gatsbyImageData = image && image.asset && image.asset.gatsbyImageData;
  const gatsbyImageDataWithJpgType = gatsbyImageData && {
    ...gatsbyImageData,
    images: {
      ...gatsbyImageData.images,
      sources:
        gatsbyImageData.images.sources &&
        gatsbyImageData.images.sources.map(source => ({
          ...source,
          type: source.type === 'image/tif' ? 'image/jpeg' : source.type,
        })),
    },
  };

  return (
    <div
      className={
        styles.moduleRoot +
        ' ' +
        (background === 'grey' ? styles.withBackground : '') +
        ' ' +
        (width === 'contained' ? styles.containedWidth : '') +
        ' ' +
        (facePhoto ? ' ' + styles.facePhotoContainer : styles.genericPhotoContainer)
      }
    >
      {title && <h3 className={styles.title}>{title}</h3>}
      <div
        className={
          styles.content +
          ' ' +
          // (facePhoto ? styles.containedContent : styles.content) +
          (imageSide !== 'left' ? ' ' + styles.reverse : '')
        }
      >
        {gatsbyImageData && (
          <GatsbyImage
            imgClassName={styles.image}
            className={facePhoto ? styles.facePhotoImageContainer : styles.imageContainer}
            alt=""
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            image={gatsbyImageDataWithJpgType!}
          ></GatsbyImage>
        )}

        <div className={styles.quoteContainer}>
          <div className={styles.quoteContent}>
            <QuoteIcon
              className={styles.quoteIcon}
              style={{ stroke: iconColor, fill: iconColor }}
            ></QuoteIcon>
            <p className={styles.quoteText}>{quote}</p>
          </div>
          <div className={styles.author} style={{ borderColor: iconColor }}>
            {author}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteModule;
