import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './index.module.scss';
import { FaSeedling } from 'react-icons/fa';

// import { COLORS } from '../../../constants';

export const MultiBoxBulletPointsModuleFragment = graphql`
  fragment MultiBoxBulletPointsModule on SanityMultiBoxBulletPointsModule {
    title {
      ...LocaleStringFragment
    }
    backgroundColorType
    # bulletPointColor {
    #   value
    # }
    sections {
      image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 780
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      singleTitle {
        ...LocaleStringFragment
      }
      bulletPoints {
        ...LocaleStringFragment
      }
    }
  }
`;

export interface MultiBoxBulletPointsModuleProps {
  title?: string;
  backgroundColorType: string;
  // bulletPointColor: {
  //   value: typeof COLORS.blue | typeof COLORS.green;
  // };
  sections: Array<{
    image: {
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    singleTitle?: string;
    bulletPoints: Array<string>;
  }>;
}

const MultiBoxBulletPointsModule = ({
  title,
  backgroundColorType,
  // bulletPointColor,
  sections,
}: MultiBoxBulletPointsModuleProps): React.ReactElement => {
  let hasSingleBulletPoint = true;
  for (const section of sections) {
    if (section.bulletPoints.length > 1) {
      hasSingleBulletPoint = false;
    }
  }
  return (
    <div className={styles.moduleRoot}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.container}>
        {sections.map((section, index) => (
          <div
            key={index}
            className={
              styles.bulletPointBox +
              (backgroundColorType === 'grey' ? ' ' + styles.bulletPointBoxBackground : '')
            }
          >
            <div className={styles.content}>
              <GatsbyImage
                alt=""
                imgClassName={styles.image}
                className={styles.imageContainer}
                image={section.image.asset.gatsbyImageData}
              ></GatsbyImage>
              <div className={styles.textBlock}>
                {section.singleTitle && (
                  <h4 className={styles.bulletPointsSingleTitle}>{section.singleTitle}</h4>
                )}
                <ul className={styles.bulletPointsList}>
                  {section.bulletPoints.map((singleBulletPoint, i) => (
                    <li key={i}>
                      {!hasSingleBulletPoint && (
                        <FaSeedling className={styles.bulletIcon}></FaSeedling>
                      )}
                      {singleBulletPoint}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiBoxBulletPointsModule;
