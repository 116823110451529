import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import * as Sentry from '@sentry/react';
import { sortBy } from './utils';

type FieldValue = string | number | boolean | null;
type Field =
  | GenericField<string>
  | GenericField<string | null>
  | GenericField<number>
  | GenericField<number | null>
  | GenericField<boolean>
  | GenericField<boolean | null>;
type SubmitState = 'disabled' | 'ready' | 'submitting' | 'error' | 'submitted';

export type GenericField<T extends FieldValue> = {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  validations?: Readonly<Array<'required' | 'email' | ((value: T) => string | null)>>;
};

export function useFormField<T extends FieldValue>(
  initialValue: T,
  validations: Readonly<Array<'required' | 'email' | ((value: T) => string | null)>>,
): GenericField<T> {
  const [value, setValue] = useState<T>(initialValue);
  const [error, setError] = useState<string>('');

  return {
    value,
    setValue,
    error,
    setError,
    validations,
  };
}

export function useForm<FieldName extends string>({
  fieldsByName,
  onSubmit,
  translateFunction = (key, defaultText) => defaultText,
}: {
  fieldsByName: Record<FieldName, Field>;
  onSubmit: () => boolean | Promise<boolean>;
  translateFunction?: (
    key:
      | 'form.required_field_error'
      | 'form.invalid_email_error'
      | 'form.network_error'
      | 'form.unknown_error'
      | 'form.success_message',
    defaultText: string,
  ) => string;
}): {
  getFieldProps: typeof getFieldProps;
  renderSubmitButton: typeof renderSubmitButton;
  renderFormMessage: typeof renderFormMessage;
  onFieldUnfocus: typeof onFieldUnfocus;
  clearFormError: typeof clearFormError;
  formMessage: string;
  hasFormError: boolean;
  submitState: SubmitState;
} {
  const fields = sortBy(
    Object.entries(fieldsByName) as Array<[FieldName, GenericField<FieldValue>]>,
    ([key]) => key,
  ).map(([, field]) => field);

  const [formMessage, setFormMessage] = useState('');
  const [hasFormError, setHasFormError] = useState(false);
  const [submitState, setSubmitState] = useState<SubmitState>(
    checkFormHasErrors() ? 'disabled' : 'ready',
  );

  function getFieldErrorMsg<T extends FieldValue>(field: GenericField<T>): string | null {
    const { value, validations } = field;
    if (!validations) {
      return null;
    }
    for (const validation of validations) {
      if (typeof validation === 'function') {
        const errorMessage = validation(value);
        if (errorMessage !== null) {
          return errorMessage;
        }
      } else {
        switch (validation) {
          case 'required':
            if (!value) {
              return translateFunction('form.required_field_error', 'This field is required.');
            }
            break;
          case 'email':
            if (typeof value === 'string' && !value.match(/^\S+@\S+\.\S+$/)) {
              return translateFunction('form.invalid_email_error', 'Invalid email format.');
            }
            break;
        }
      }
    }
    return null;
  }

  function onFieldUnfocus<T extends FieldValue>(field: GenericField<T>): void {
    const errorMsg = getFieldErrorMsg(field);
    if (errorMsg !== null) {
      field.setError(errorMsg);
    }
  }

  function checkFormHasErrors(): boolean {
    for (const field of fields) {
      if (getFieldErrorMsg(field) !== null) {
        return true;
      }
    }
    return false;
  }

  async function internalOnSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setFormMessage('');
    setHasFormError(false);
    if (submitState !== 'ready' || checkFormHasErrors()) {
      return;
    }

    setSubmitState('submitting');

    try {
      const success = await onSubmit();
      if (success) {
        setSubmitState('submitted');
        setFormMessage(
          translateFunction(
            'form.success_message',
            "Thank you for your message, we'll contact you shortly.",
          ),
        );
      } else {
        setSubmitState('ready');
      }
    } catch (error) {
      setSubmitState('error');
      setHasFormError(true);
      if (
        error &&
        typeof error === 'object' &&
        (error as { message: string }).message === 'Failed to fetch'
      ) {
        setFormMessage(
          translateFunction('form.network_error', 'Network failed to send your request.'),
        );
      } else {
        setFormMessage(translateFunction('form.unknown_error', 'An unknown error has occurred.'));
        Sentry.captureException(error);
      }
    }
  }

  function clearFormError() {
    if (checkFormHasErrors()) {
      setSubmitState('disabled');
    } else {
      setSubmitState('ready');
    }
    setFormMessage('');
    setHasFormError(false);
  }

  function renderSubmitButton({
    id,
    labels,
    btnClasses,
    iconClasses,
  }: {
    id?: string;
    labels: {
      disabled: string;
      ready: string;
      submitting: string;
      error: string;
      submitted: string;
    };
    btnClasses?: {
      common?: string;
      disabled?: string;
      ready?: string;
      submitting?: string;
      error?: string;
      submitted?: string;
    };
    iconClasses?: {
      disabled?: string | React.ReactElement;
      ready?: string | React.ReactElement;
      submitting?: string | React.ReactElement;
      error?: string | React.ReactElement;
      submitted?: string | React.ReactElement;
    };
  }) {
    const btnClass = btnClasses && clsx(btnClasses.common, btnClasses[submitState]);
    const iconClassOrElement = iconClasses && iconClasses[submitState];
    return (
      <button id={id} className={btnClass} type="submit" tabIndex={0} onClick={internalOnSubmit}>
        {iconClassOrElement &&
          (typeof iconClassOrElement === 'string' ? (
            <i className={iconClassOrElement}></i>
          ) : (
            iconClassOrElement
          ))}
        {labels[submitState]}
      </button>
    );
  }

  function renderFormMessage({
    styles,
  }: {
    styles: {
      formMessage?: string;
      formMessageSuccess?: string;
      formMessageError?: string;
    };
  }) {
    if (!formMessage) {
      return null;
    }
    return (
      <div
        className={clsx(
          styles.formMessage,
          formMessage && !hasFormError && styles.formMessageSuccess,
          formMessage && hasFormError && styles.formMessageError,
        )}
      >
        <span>{formMessage}</span>
      </div>
    );
  }

  function getFieldProps<T extends FieldValue>(
    field: GenericField<T>,
    options?: {
      setValuePreprocessor?: (value: T) => T;
      defaultHelperText?: string;
    },
  ): {
    disabled: boolean;
    value: T;
    onChange: (value: T) => void;
    onBlur: () => void;
    error: boolean;
    helperText: string;
  } {
    return {
      disabled: submitState === 'submitted',
      value: field.value,
      onChange: (value: T) => {
        field.setValue(
          options && options.setValuePreprocessor ? options.setValuePreprocessor(value) : value,
        );
        field.setError('');
      },
      onBlur: () => onFieldUnfocus(field),
      error: !!field.error,
      helperText: field.error || (options && options.defaultHelperText) || ' ',
    };
  }

  useEffect(
    () => {
      setFormMessage('');
      setHasFormError(false);
      if (!checkFormHasErrors() && submitState === 'disabled') {
        setSubmitState('ready');
      } else if (checkFormHasErrors() && submitState === 'ready') {
        setSubmitState('disabled');
      }
    },
    fields.map(field => field.value),
  );

  return {
    getFieldProps,
    renderSubmitButton,
    renderFormMessage,
    onFieldUnfocus,
    clearFormError,
    formMessage,
    hasFormError,
    submitState,
  };
}
