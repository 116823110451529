import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { RawPortableText } from '../../../types';
import BlockContent from '@sanity/block-content-to-react';

import { FaChevronDown } from 'react-icons/fa';
import pyramid from '../../../assets/images/pyramid.png';
import * as styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

export const PyramidModuleFragment = graphql`
  fragment PyramidModule on SanityPyramidModule {
    title {
      ...LocaleStringFragment
    }
    paragraph {
      ...LocaleStringFragment
    }
    bulletpoints {
      ...LocaleStringFragment
    }
    levelOne {
      ...LocaleStringFragment
    }
    levelTwo {
      ...LocaleStringFragment
    }
    levelThree {
      ...LocaleStringFragment
    }
    levelFour {
      ...LocaleStringFragment
    }
    levelFive {
      ...LocaleStringFragment
    }
    seeMorePortableText {
      ...LocaleSimplePortableText
    }
  }
`;

export interface PyramidModuleProps {
  title?: string;
  paragraph: string;
  bulletpoints: Array<string>;
  levelOne: string;
  levelTwo: string;
  levelThree: string;
  levelFour: string;
  levelFive: string;
  seeMorePortableText?: RawPortableText;
}

const PyramidModule = ({
  title,
  paragraph,
  bulletpoints,
  levelOne,
  levelTwo,
  levelThree,
  levelFour,
  levelFive,
  seeMorePortableText,
}: PyramidModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const [isSeeMoreOpen, setIsSeeMoreOpen] = useState(false);

  return (
    <div className={styles.moduleRoot}>
      {title && <h3>{title}</h3>}
      <div className={styles.container}>
        <h4 className={styles.paragraph}>{paragraph}</h4>
        <div className={styles.bulletPointsBox}>
          {bulletpoints.map((bulletpoint, index) => {
            return (
              <div key={index} className={styles.bulletPoint}>
                <div className={styles.bulletPointRectangle}></div>
                <span className={styles.bulletPointText + ' titleParagraph'}>{bulletpoint}</span>
              </div>
            );
          })}
        </div>
        <div className={styles.line}></div>
        <div className={styles.pyramidContainer}>
          <div className={styles.imageContainer}>
            <img src={pyramid} alt="" />
          </div>
          <div className={styles.textContent}>
            <div className={styles.level}>
              <div className={styles.rectangle}></div>
              <span>{levelOne}</span>
            </div>
            <div className={styles.level}>
              <div className={styles.rectangle}></div>
              <span>{levelTwo}</span>
            </div>
            <div className={styles.level}>
              <div className={styles.rectangle}></div>
              <span>{levelThree}</span>
            </div>
            <div className={styles.level}>
              <div className={styles.rectangle}></div>
              <span>{levelFour}</span>
            </div>
            <div className={styles.level}>
              <div className={styles.rectangle}></div>
              <span>{levelFive}</span>
            </div>
          </div>
        </div>
        {seeMorePortableText && (
          <div className={styles.seeMoreContainer}>
            <span
              className={styles.seeMoreLink + ' ' + (isSeeMoreOpen ? styles.seeMoreOpen : '')}
              onClick={() => setIsSeeMoreOpen(!isSeeMoreOpen)}
            >
              {!isSeeMoreOpen
                ? t('module_pyramid.see_more_label', 'See More')
                : t('module_pyramid.see_less_label', 'See Less')}{' '}
              <FaChevronDown className={styles.linkIcon}></FaChevronDown>
            </span>
            {isSeeMoreOpen && (
              <BlockContent renderContainerOnSingleChild blocks={seeMorePortableText} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PyramidModule;
