// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--7MGuj";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var description = "index-module--description--oQWFi";
export var error = "#f66a83";
export var footerLink = "index-module--footerLink--xj2EZ";
export var green = "#3dae2b";
export var grey = "#828382";
export var iconsPerRow3 = "index-module--iconsPerRow3--wlrIU";
export var iconsPerRow4 = "index-module--iconsPerRow4--W8uOd";
export var iconsPerRow5 = "index-module--iconsPerRow5--CME9R";
export var iconsPerRow6 = "index-module--iconsPerRow6--34hdJ";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--qBQE0";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var paragraphWrapper = "index-module--paragraphWrapper--aq6cW";
export var stampContainer = "index-module--stampContainer---PanJ";
export var stampIconContainer = "index-module--stampIconContainer--R3Hgv";
export var success = "#1ebc7b";
export var title = "index-module--title--Mg76n";
export var titleParagraph = "index-module--titleParagraph--4BqzU";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";