import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as styles from './index.module.scss';
import GenericLink from '../../GenericLink';
import { useLocalization } from '../../../utils/hooks';

export const ContactsModuleFragment = graphql`
  fragment ContactsModule on SanityContactsModule {
    title {
      ...LocaleStringFragment
    }
    mapContacts {
      title {
        ...LocaleStringFragment
      }
      address {
        ...LocaleStringFragment
      }
      googleMapsAddress
    }
    contacts {
      title {
        ...LocaleStringFragment
      }
      address {
        ...LocaleTextFragment
      }
      email
      phoneNumber
    }
  }
`;

export interface ContactsModuleProps {
  title: string;
  mapContacts: Array<{
    title: string;
    address: string;
    googleMapsAddress?: string;
  }>;
  contacts: Array<{
    title: string;
    address?: string;
    email?: string;
    phoneNumber?: string;
  }>;
}

interface LocalizedQueryData {
  site: {
    siteMetadata: {
      googleMapsAPIKey: string;
    };
  };
}

const ContactsModule = ({
  title,
  mapContacts,
  contacts,
}: ContactsModuleProps): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      query GetContactUsData {
        site {
          siteMetadata {
            googleMapsAPIKey
          }
        }
      }
    `,
  );
  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const { googleMapsAPIKey } = localizedData.site.siteMetadata;

  const contactsPerRowClass = {
    3: styles.contactsPerRow3,
    4: styles.contactsPerRow4,
    5: styles.contactsPerRow5,
    6: styles.contactsPerRow6,
  }[contacts.length > 6 ? 6 : contacts.length];

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.mapContactsContainer}>
          {mapContacts.map((mapContact, index) => (
            <div key={index} className={styles.mapContact}>
              {/* <h3 className={styles.mapContactTitle}>{mapContact.title}</h3> */}
              <iframe
                height="400"
                frameBorder="0"
                style={{ width: '100%', border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?q=${
                  mapContact.googleMapsAddress || mapContact.address
                }&key=${googleMapsAPIKey}`}
                allowFullScreen
              ></iframe>
              <div className={styles.mapContactContent}>
                <h4 className={styles.contactTitle}>{mapContact.title}</h4>
                <GenericLink
                  noExternalUrlIcon
                  to={
                    'https://www.google.com/maps/search/?api=1&query=' +
                    encodeURI(mapContact.address)
                  }
                  className={styles.address}
                >
                  {mapContact.address}
                </GenericLink>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.contactsContainer}>
          {contacts.map(({ title, address, email, phoneNumber }, index) => (
            <div key={index} className={styles.contact + ' ' + contactsPerRowClass}>
              <h4 className={styles.contactTitle}>{title}</h4>
              {address && (
                <GenericLink
                  noExternalUrlIcon
                  to={'https://www.google.com/maps/search/?api=1&query=' + encodeURI(address)}
                  className={styles.address}
                >
                  {address}
                </GenericLink>
              )}
              {email && (
                <GenericLink className={styles.email} noExternalUrlIcon to={'mailto:' + email}>
                  {email}
                </GenericLink>
              )}
              {phoneNumber && (
                <GenericLink
                  className={styles.phoneNumber}
                  noExternalUrlIcon
                  to={'tel:' + phoneNumber}
                >
                  {phoneNumber}
                </GenericLink>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactsModule;
