import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FaChevronRight, FaTag } from 'react-icons/fa';
import getYouTubeID from 'get-youtube-id';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import FileRow from '../../FileRow';
import AssetsPhoto from '../../AssetsPhoto';
import Video from '../../Video';
import { sortBy } from '../../../utils/utils';
import { OpenTermsModalContext } from '../../../contexts/OpenTermsModalContext';

import * as styles from './index.module.scss';
import { useLocalization } from '../../../utils/hooks';
import LocalizedLink from '../../LocalizedLink';

import { clsx } from '../../../utils/utils';

export const AssetsModuleFragment = graphql`
  fragment AssetsModule on SanityAssetsModule {
    ... on SanityAssetsModule {
      label
      assetsSource
      centeredContent
      customPhotosTitle {
        ...LocaleStringFragment
      }
      customPhotos {
        label1 {
          ...LocaleStringFragment
        }
        label2 {
          ...LocaleStringFragment
        }
        category {
          title {
            ...LocaleStringFragment
          }
        }
        image {
          asset {
            url
            gatsbyImageData(
              layout: CONSTRAINED
              width: 450
              height: 450
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            extension
          }
        }
        priority
      }
      customVideosTitle {
        ...LocaleStringFragment
      }
      customVideos {
        name {
          ...LocaleStringFragment
        }
        category {
          title {
            ...LocaleStringFragment
          }
        }
        url {
          ...LocaleStringFragment
        }
        date
        description {
          ...LocaleTextFragment
        }
      }
      customFilesTitle {
        ...LocaleStringFragment
      }
      customFiles {
        name {
          ...LocaleStringFragment
        }
        description {
          ...LocaleTextFragment
        }
        file {
          asset {
            url
            size
          }
        }
        category {
          title {
            ...LocaleStringFragment
          }
        }
        date
        priority
      }
    }
  }
`;

export type AssetsModuleProps = {
  label: string;
} & (
  | {
      assetsSource: 'custom';
      centeredContent?: boolean;
      customPhotosTitle?: string;
      customPhotos?: Array<IAssetsPhoto>;
      customVideosTitle?: string;
      customVideos?: Array<AssetsVideo>;
      customFilesTitle?: string;
      customFiles?: Array<AssetsFile>;
    }
  | {
      assetsSource: 'default' | null;
      centeredContent?: never;
      customPhotosTitle?: never;
      customPhotos?: never;
      customVideosTitle?: never;
      customVideos?: never;
      customFilesTitle?: never;
      customFiles?: never;
    }
);

interface IAssetsPhoto {
  label1: string;
  label2?: string;
  category: {
    title: string;
  };
  image: {
    asset: {
      url: string;
      gatsbyImageData: IGatsbyImageData;
      extension: string;
    };
  };
  priority: number | null;
}

interface AssetsVideo {
  name: string;
  category: {
    title: string;
  };
  url: string;
  date: string;
  description: string;
}

interface AssetsFile {
  name: string;
  description: string;
  file: {
    asset: {
      url: string;
      size: number;
    };
  };
  category: {
    title: string;
  };
  date: string;
  priority: number | null;
}

interface LocalizedQueryData {
  allSanityAssetsPhoto: {
    nodes: Array<IAssetsPhoto>;
  };
  allSanityAssetsVideo: {
    nodes: Array<AssetsVideo>;
  };
  allSanityAssetsFile: {
    nodes: Array<AssetsFile>;
  };
}

interface Assets {
  photos?: Array<IAssetsPhoto>;
  videos?: Array<AssetsVideo>;
  files?: Array<AssetsFile>;
}

function parseDate(dateStr: string): Date {
  return new Date(dateStr + 'T00:00:00Z');
}

const MAX_NUMBER_OF_IMAGES = 5;
const MAX_NUMBER_OF_VIDEOS = 3;

const AssetsModule = ({
  assetsSource,
  centeredContent,
  customPhotosTitle,
  customPhotos,
  customVideosTitle,
  customVideos,
  customFilesTitle,
  customFiles,
}: AssetsModuleProps): React.ReactElement => {
  assetsSource = assetsSource || 'default';
  const openModalIfTermsNotAccepted = useContext(OpenTermsModalContext);

  const data = useStaticQuery(graphql`
    {
      allSanityAssetsPhoto {
        nodes {
          label1 {
            ...LocaleStringFragment
          }
          label2 {
            ...LocaleStringFragment
          }
          category {
            title {
              ...LocaleStringFragment
            }
          }
          image {
            asset {
              url
              gatsbyImageData(
                layout: CONSTRAINED
                width: 450
                height: 450
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
              extension
            }
          }
          priority
        }
      }
      allSanityAssetsVideo {
        nodes {
          name {
            ...LocaleStringFragment
          }
          category {
            title {
              ...LocaleStringFragment
            }
          }
          url {
            ...LocaleStringFragment
          }
          date
          description {
            ...LocaleTextFragment
          }
        }
      }
      allSanityAssetsFile {
        nodes {
          name {
            ...LocaleStringFragment
          }
          description {
            ...LocaleTextFragment
          }
          file {
            asset {
              url
              size
            }
          }
          category {
            title {
              ...LocaleStringFragment
            }
          }
          date
          priority
        }
      }
    }
  `);

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);

  function getDefaultAssets(): Assets {
    const { nodes: assetsFiles } = localizedData.allSanityAssetsFile;
    const { nodes: assetsPhotos } = localizedData.allSanityAssetsPhoto;
    const { nodes: assetsVideos } = localizedData.allSanityAssetsVideo;

    const allFilesSorted = sortBy(
      assetsFiles,
      file => (file.priority !== null ? file.priority : Number.NEGATIVE_INFINITY),
      'desc',
    );
    const allPhotosSorted = sortBy(
      assetsPhotos,
      photo => (photo.priority !== null ? photo.priority : Number.NEGATIVE_INFINITY),
      'desc',
    );
    const allVideosSorted = sortBy(assetsVideos, video => parseDate(video.date), 'desc');

    const photoCategories: Array<string> = [];
    for (const photoCategory of allPhotosSorted) {
      if (!photoCategories.some(title => title === photoCategory.category.title)) {
        photoCategories.push(photoCategory.category.title);
      }
    }

    const videoCategories: Array<string> = [];
    for (const videoCategory of allVideosSorted) {
      if (!videoCategories.some(title => title === videoCategory.category.title)) {
        videoCategories.push(videoCategory.category.title);
      }
    }

    const selectedAssetsPhotos: Array<IAssetsPhoto> = [];
    let i = 0;
    while (selectedAssetsPhotos.length < Math.min(MAX_NUMBER_OF_IMAGES, allPhotosSorted.length)) {
      const currentAssetsPhoto = allPhotosSorted.filter(
        assetsPhoto => assetsPhoto.category.title === photoCategories[i % photoCategories.length],
      )[Math.floor(i / photoCategories.length)];
      if (currentAssetsPhoto) {
        selectedAssetsPhotos.push(currentAssetsPhoto);
      }
      i++;
    }

    const selectedAssetsVideos: Array<AssetsVideo> = [];
    i = 0;
    while (selectedAssetsVideos.length < Math.min(MAX_NUMBER_OF_VIDEOS, allVideosSorted.length)) {
      const currentAssetsVideo = allVideosSorted.filter(
        assetsVideo => assetsVideo.category.title === videoCategories[i % videoCategories.length],
      )[Math.floor(i / videoCategories.length)];
      if (currentAssetsVideo) {
        selectedAssetsVideos.push(currentAssetsVideo);
      }
      i++;
    }

    return {
      photos: selectedAssetsPhotos,
      videos: selectedAssetsVideos,
      files: allFilesSorted,
    };
  }

  function getCustomAssets(): Assets {
    return {
      photos: customPhotos,
      videos: customVideos,
      files: customFiles,
    };
  }

  const { files, photos, videos } =
    assetsSource === 'default' ? getDefaultAssets() : getCustomAssets();

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {photos && photos.length > 0 && (
          <div
            className={
              styles.assetsContainer +
              (assetsSource === 'custom' && centeredContent ? ' ' + styles.centeredContent : '')
            }
          >
            {customPhotosTitle && <h4 className={styles.assetsTitle}>{customPhotosTitle}</h4>}
            {assetsSource === 'default' && (
              <h4 className={styles.assetsTitle}>
                {t('module_assets.photos_title', 'Latest photos')}
              </h4>
            )}

            <div
              className={clsx(
                styles.photosContainer,
                assetsSource === 'default' ? styles.defaultPhotosContainer : '',
              )}
            >
              {photos.map(
                (assetsPhoto, index) =>
                  !!(assetsPhoto.image && assetsPhoto.image.asset) && (
                    <AssetsPhoto
                      label1={assetsPhoto.label1}
                      label2={assetsPhoto.label2}
                      image={assetsPhoto.image}
                      category={assetsPhoto.category}
                      key={index}
                      className={styles.photo}
                      onDownloadClick={openModalIfTermsNotAccepted}
                    ></AssetsPhoto>
                  ),
              )}
            </div>
            {assetsSource === 'default' && (
              <LocalizedLink to={'/photos/'} className={'titleParagraph ' + styles.link}>
                {t('module_assets.view_all_link', 'View All')}
                <FaChevronRight className={styles.iconRight}></FaChevronRight>
              </LocalizedLink>
            )}
          </div>
        )}
        {videos && videos.length > 0 && (
          <div
            className={
              styles.assetsContainer +
              (assetsSource === 'custom' && centeredContent ? ' ' + styles.centeredContent : '')
            }
          >
            {customVideosTitle && <h4 className={styles.assetsTitle}>{customVideosTitle}</h4>}
            {assetsSource === 'default' && (
              <h4 className={styles.assetsTitle}>
                {t('module_assets.videos_title', 'Latest videos')}
              </h4>
            )}
            <div
              className={clsx(
                styles.videosContainer,
                assetsSource === 'default'
                  ? styles.defaultVideosContainer
                  : styles.customVideosContainer,
              )}
            >
              {videos.map((assetsVideo, index) => {
                const id = getYouTubeID(assetsVideo.url);
                if (!id) {
                  throw new Error('Could not extract youtube id from url: ' + assetsVideo.url);
                }
                const embedUrl = `https://www.youtube.com/embed/${id}`;
                return (
                  <div className={styles.video} key={index}>
                    <div className={styles.videoContent}>
                      <Video url={embedUrl}></Video>
                      <div className={styles.categoryContainer}>
                        <FaTag className={styles.categoryIcon}></FaTag>
                        <span className={styles.categoryTitle + ' titleParagraph'}>
                          {assetsVideo.category.title}
                        </span>
                      </div>
                    </div>
                    <span className={styles.videoSubtitle}>{assetsVideo.name}</span>
                  </div>
                );
              })}
            </div>
            {assetsSource === 'default' && (
              <LocalizedLink to={'/videos/'} className={'titleParagraph ' + styles.link}>
                {t('module_assets.view_all_link', 'View All')}
                <FaChevronRight className={styles.iconRight}></FaChevronRight>
              </LocalizedLink>
            )}
          </div>
        )}
        {files && files.length > 0 && (
          <div
            className={
              styles.assetsContainer +
              ' ' +
              styles.assetsFilesContainer +
              (assetsSource === 'custom' && centeredContent ? ' ' + styles.centeredContent : '')
            }
          >
            {customFilesTitle && <h4 className={styles.assetsTitle}>{customFilesTitle}</h4>}
            {assetsSource === 'default' && (
              <h4 className={styles.assetsTitle}>
                {t('module_assets.documents_title', 'Documents')}
              </h4>
            )}
            {files.map(
              (assetsFile, index) =>
                !!(assetsFile.file && assetsFile.file.asset) && (
                  <FileRow
                    key={index}
                    title={assetsFile.name}
                    description={assetsFile.description}
                    size={assetsFile.file.asset.size}
                    url={assetsFile.file.asset.url}
                    category={assetsFile.category.title}
                    date={parseDate(assetsFile.date)}
                    onDownloadClick={openModalIfTermsNotAccepted}
                  ></FileRow>
                ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsModule;
