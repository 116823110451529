// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var cardContainer = "NewsCard-module--cardContainer--ZeNUE";
export var categoryContentTypeBar = "NewsCard-module--categoryContentTypeBar--Kntui";
export var categoryIcon = "NewsCard-module--categoryIcon--G9NV8";
export var categoryTitle = "NewsCard-module--categoryTitle--VEvAD";
export var content = "NewsCard-module--content--N-WVq";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var date = "NewsCard-module--date--wyqJk";
export var error = "#f66a83";
export var extract = "NewsCard-module--extract--GsScT";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "NewsCard-module--image--jfV0M";
export var imageContainer = "NewsCard-module--imageContainer--3o4kG";
export var imageContainerWrapper = "NewsCard-module--imageContainerWrapper--i2+B3";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "NewsCard-module--link--qd3GT";
export var linkIcon = "NewsCard-module--linkIcon--oGcd3";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var title = "NewsCard-module--title--bwNyh";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";