import React from 'react';
import { getUrlFromVersatileLink, hexToRgb } from '../../../utils/utils';

import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { RawPortableText } from '../../../types';
import BlockContent from '@sanity/block-content-to-react';

import * as styles from './index.module.scss';

import { COLORS } from '../../../constants';
import ReadMoreLink from '../../ReadMoreLink';
import { VersatileLinkMaybeEmpty } from '../../../graphql-fragments/versatileLink';
import { useTranslation } from 'react-i18next';

export const DoubleTnIModuleFragment = graphql`
  fragment ImageCard on SanityImageCard {
    imageCardPortableText {
      ...LocaleSimplePortableText
    }
    imageCardTitle {
      ...LocaleStringFragment
    }
    image {
      asset {
        url
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1240
          height: 680
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageCardVersatileLink {
      ...VersatileLink
    }
  }
  fragment DoubleTnIModule on SanityDoubleTnIModule {
    title {
      ...LocaleStringFragment
    }
    baseColor {
      value
    }
    leftImageCard {
      ...ImageCard
    }
    rightImageCard {
      ...ImageCard
    }
  }
`;

interface ImageCard {
  imageCardPortableText: RawPortableText;
  imageCardTitle: string;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  imageCardVersatileLink?: VersatileLinkMaybeEmpty;
}

export interface DoubleTnIModuleProps {
  title?: string;
  baseColor: {
    value: typeof COLORS.darkBlue;
  };
  leftImageCard: ImageCard;
  rightImageCard: ImageCard;
}

const DoubleTnIModule = ({
  title,
  baseColor,
  leftImageCard,
  rightImageCard,
}: DoubleTnIModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && (
          <h3 className={styles.title} style={{ color: COLORS.darkGreen }}>
            {title}
          </h3>
        )}
        <div className={styles.content}>
          {[leftImageCard, rightImageCard].map((card, i) => (
            <div key={i} className={styles.card}>
              <div className={styles.imageWrapper}>
                <GatsbyImage
                  alt={''}
                  className={styles.imageContainer}
                  imgClassName={styles.image}
                  image={card.image.asset.gatsbyImageData}
                ></GatsbyImage>
                <div
                  className={styles.titleBar}
                  style={{
                    backgroundColor: 'rgba(' + hexToRgb(baseColor.value).join(',') + ',0.7)',
                  }}
                >
                  <h3 className={styles.title}>{card.imageCardTitle}</h3>
                </div>
              </div>
              <div className={styles.infoContent}>
                <BlockContent
                  className={styles.cardParagraph}
                  renderContainerOnSingleChild
                  blocks={card.imageCardPortableText}
                />

                {(card.imageCardVersatileLink?.url ||
                  card.imageCardVersatileLink?.pageReference) && (
                  <ReadMoreLink
                    className={styles.link}
                    to={getUrlFromVersatileLink(card.imageCardVersatileLink)}
                  >
                    {t('module_double_text_and_image.read_more_link', 'Read more')}
                  </ReadMoreLink>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoubleTnIModule;
