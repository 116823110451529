// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var assetsContainer = "index-module--assetsContainer--0U0Kr";
export var assetsTitle = "index-module--assetsTitle--7Jkhf";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var categoryContainer = "index-module--categoryContainer--bRdCN";
export var categoryIcon = "index-module--categoryIcon--e7JIc";
export var categoryTitle = "index-module--categoryTitle--z0q5z";
export var centeredContent = "index-module--centeredContent--rxgJQ";
export var container = "index-module--container--VK5gK";
export var coolGrey = "#e1e2e0";
export var customVideosContainer = "index-module--customVideosContainer--zisbL";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var defaultPhotosContainer = "index-module--defaultPhotosContainer--c8Z4n";
export var defaultVideosContainer = "index-module--defaultVideosContainer--NkwWF";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var iconRight = "index-module--iconRight--Xa-kM";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "index-module--link--c1VSi";
export var moduleRoot = "index-module--moduleRoot--b3Vua";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var photo = "index-module--photo--+wgl0";
export var photosContainer = "index-module--photosContainer--abuED";
export var success = "#1ebc7b";
export var video = "index-module--video--+ujHF";
export var videoContent = "index-module--videoContent--3QmMA";
export var videoSubtitle = "index-module--videoSubtitle--8SrtT";
export var videosContainer = "index-module--videosContainer--eGuYq";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";