import React from 'react';
import { graphql } from 'gatsby';
import { RawPortableText } from '../../../types';
import BlockContent from '@sanity/block-content-to-react';

import * as styles from './index.module.scss';

export const MultiTnIconModuleFragment = graphql`
  fragment MultiTnIconModule on SanityMultiTnIconModule {
    title {
      ...LocaleStringFragment
    }
    portableTextParagraph {
      ...LocaleSimplePortableText
    }
    tnIcons {
      icon {
        asset {
          url
        }
      }
      text {
        ...LocaleStringFragment
      }
    }
  }
`;

export interface MultiTnIconModuleProps {
  title?: string;
  portableTextParagraph: RawPortableText;
  tnIcons: Array<{
    icon: {
      asset: {
        url: string;
      };
    };
    text: string;
  }>;
}

const MultiTnIconModule = ({
  title,
  portableTextParagraph,
  tnIcons,
}: MultiTnIconModuleProps): React.ReactElement => {
  const iconsPerRowClass = {
    3: styles.iconsPerRow3,
    4: styles.iconsPerRow4,
    5: styles.iconsPerRow5,
    6: styles.iconsPerRow6,
  }[tnIcons.length > 6 ? 6 : tnIcons.length];

  return (
    <section className={styles.moduleRoot}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <BlockContent
        className={styles.paragraphWrapper}
        renderContainerOnSingleChild
        blocks={portableTextParagraph}
      />
      <div className={styles.container}>
        {tnIcons.map(({ icon, text }, index) => {
          return (
            <div key={index} className={styles.stampContainer + ' ' + iconsPerRowClass}>
              <div className={styles.stampIconContainer}>
                <img src={icon.asset.url}></img>
              </div>
              {text && <span className={styles.description}>{text}</span>}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MultiTnIconModule;
