// extracted by mini-css-extract-plugin
export var active = "index-module--active--sIpwA";
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var cardContent = "index-module--cardContent--Fn2+X";
export var container = "index-module--container--kSHIT";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "index-module--icon---NohG";
export var iframe = "index-module--iframe--2Kz6L";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var mainVideo = "index-module--mainVideo--YQgFY";
export var moduleRoot = "index-module--moduleRoot--Ufbb3";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var scrollableBox = "index-module--scrollableBox--UwGK9";
export var success = "#1ebc7b";
export var thumbnailContainer = "index-module--thumbnailContainer--0PnM5";
export var videoCard = "index-module--videoCard--qLxHE";
export var videoDuration = "index-module--videoDuration---UXei";
export var videoList = "index-module--videoList--bn-wp";
export var videoListContainer = "index-module--videoListContainer--Ynsfp";
export var videoListTitle = "index-module--videoListTitle--IOyx8";
export var videoTitle = "index-module--videoTitle--sEKWy";
export var videos = "index-module--videos--HA4yG";
export var warning = "#ffeda0";
export var white = "#fff";
export var withBackground = "index-module--withBackground--MepjQ";
export var yellow = "#fbc900";