// extracted by mini-css-extract-plugin
export var address = "index-module--address--ND8Sl";
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var contact = "index-module--contact--FDGmo";
export var contactTitle = "index-module--contactTitle--7K9tr";
export var contactsContainer = "index-module--contactsContainer--8MasK";
export var contactsPerRow3 = "index-module--contactsPerRow3--07SYT";
export var contactsPerRow4 = "index-module--contactsPerRow4--7MBHg";
export var contactsPerRow5 = "index-module--contactsPerRow5--q0bGj";
export var contactsPerRow6 = "index-module--contactsPerRow6--01zeK";
export var container = "index-module--container--pXcLI";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var email = "index-module--email--nEGZe";
export var error = "#f66a83";
export var footerLink = "index-module--footerLink--byuIG";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var mapContact = "index-module--mapContact--sT4bd";
export var mapContactContent = "index-module--mapContactContent--DGKM5";
export var mapContactsContainer = "index-module--mapContactsContainer--OJirQ";
export var moduleRoot = "index-module--moduleRoot--qtJUv";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var phoneNumber = "index-module--phoneNumber--YaA6Q";
export var success = "#1ebc7b";
export var title = "index-module--title--PG9V4";
export var titleParagraph = "index-module--titleParagraph--80uxk";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";