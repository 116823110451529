import React from 'react';
import {
  TextField as MUITextField,
  StandardTextFieldProps as MUITextFieldProps,
} from '@material-ui/core';

import * as styles from './TextField.module.scss';

type TextFieldProps = Omit<MUITextFieldProps, 'onChange'> & {
  onChange: (value: string) => void;
};

const TextField = ({ onChange, name, ...restProps }: TextFieldProps): React.ReactElement => {
  return (
    <MUITextField
      id={name + '-input'}
      name={name}
      InputLabelProps={{ shrink: true }}
      className={styles.input}
      onChange={e => onChange(e.target.value)}
      {...restProps}
    />
  );
};

export default TextField;
