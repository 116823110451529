import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { FiMapPin } from 'react-icons/fi';

import * as styles from './index.module.scss';
import mapMobileImg from '../../../assets/images/map-mobile.svg';
import CountryContactsCard, { CountryPresenceType } from '../../CountryContactsCard';
import { MapCountryCode, WorldwideMap } from '../../WorldwideMap';
import { Portal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const WorldwideModuleFragment = graphql`
  fragment CountryFragment on SanityWorldwideModuleCountry {
    presenceType
    contacts {
      name {
        ...LocaleStringFragment
      }
      address
      phoneNumber
      email
      website
    }
  }

  fragment WorldwideModule on SanityWorldwideModule {
    title {
      ...LocaleStringFragment
    }
    countries {
      pt {
        ...CountryFragment
      }
      es {
        ...CountryFragment
      }
      fr {
        ...CountryFragment
      }
      it {
        ...CountryFragment
      }
      mx {
        ...CountryFragment
      }
      br {
        ...CountryFragment
      }
      ro {
        ...CountryFragment
      }
      hr {
        ...CountryFragment
      }
      gr {
        ...CountryFragment
      }
      bu {
        ...CountryFragment
      }
    }
  }
`;

interface WorldwideCountry {
  presenceType: CountryPresenceType;
  contacts: Array<{
    name: string;
    address?: string;
    phoneNumber?: string;
    email?: string;
    website?: string;
  }>;
}
export interface WorldwideModuleProps {
  title?: string;
  countries: {
    pt: WorldwideCountry;
    es: WorldwideCountry;
    fr: WorldwideCountry;
    it: WorldwideCountry;
    mx: WorldwideCountry;
    br: WorldwideCountry;
    ro: WorldwideCountry;
    hr: WorldwideCountry;
    gr: WorldwideCountry;
    bu: WorldwideCountry;
  };
}

const WorldwideModule = ({ title, countries }: WorldwideModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const COUNTRY_NAME_BY_COUNTRY_CODE = {
    pt: t('module_worldwide.country_name.portugal', 'Portugal'),
    es: t('module_worldwide.country_name.spain', 'Spain'),
    fr: t('module_worldwide.country_name.france', 'France'),
    it: t('module_worldwide.country_name.italy', 'Italy'),
    mx: t('module_worldwide.country_name.mexico', 'Mexico'),
    br: t('module_worldwide.country_name.brazil', 'Brazil'),
    ro: t('module_worldwide.country_name.romania', 'Romania'),
    hr: t('module_worldwide.country_name.croatia', 'Croatia'),
    gr: t('module_worldwide.country_name.greece', 'Greece'),
    bu: t('module_worldwide.country_name.bulgaria', 'Bulgaria'),
  };

  const CONTINENT_BY_COUNTRY_CODE = {
    pt: t('module_worldwide.continent_name.europe', 'Europe'),
    es: t('module_worldwide.continent_name.europe', 'Europe'),
    fr: t('module_worldwide.continent_name.europe', 'Europe'),
    it: t('module_worldwide.continent_name.europe', 'Europe'),
    mx: t('module_worldwide.continent_name.latin_america', 'Latin America'),
    br: t('module_worldwide.continent_name.latin_america', 'Latin America'),
    ro: t('module_worldwide.continent_name.europe', 'Europe'),
    hr: t('module_worldwide.continent_name.europe', 'Europe'),
    gr: t('module_worldwide.continent_name.europe', 'Europe'),
    bu: t('module_worldwide.continent_name.europe', 'Europe'),
  };

  const [selectedCountry, setSelectedCountry] = useState<MapCountryCode | null>(null);
  const [hidingCountry, setHidingCountry] = useState<boolean>(false);
  const selectedCountryData = selectedCountry && countries[selectedCountry];
  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <div
          className={
            styles.desktopMapAndContacts +
            (selectedCountry ? ' ' + styles.withSelectedCountry : '') +
            (hidingCountry ? ' ' + styles.withHidingCountry : '')
          }
        >
          <div className={styles.mapWrapper}>
            <WorldwideMap
              selectedCountry={selectedCountry}
              onSelectCountry={country => {
                if (country === selectedCountry) {
                  setHidingCountry(true);
                  setTimeout(() => {
                    setHidingCountry(false);
                    setSelectedCountry(null);
                  }, 500);
                  return;
                }
                setHidingCountry(true);
                setTimeout(() => {
                  setHidingCountry(false);
                  setSelectedCountry(country);
                }, 500);
              }}
              changingCountry={hidingCountry}
            ></WorldwideMap>
          </div>
          <div className={styles.contactsCardWrapper}>
            {!!selectedCountry && !!selectedCountryData ? (
              <CountryContactsCard
                countryName={COUNTRY_NAME_BY_COUNTRY_CODE[selectedCountry]}
                countryPresenceType={selectedCountryData.presenceType}
                contacts={selectedCountryData.contacts}
              ></CountryContactsCard>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className={styles.mobileMapAndContacts}>
          <img className={styles.mapImage} src={mapMobileImg}></img>
          <div className={styles.continents}>
            {['Europe', 'Latin America'].map(continentName => (
              <div key={continentName} className={styles.continent}>
                <div className={styles.continentLabel}>{continentName}</div>
                <div className={styles.countries}>
                  {(Object.keys(countries) as Array<keyof typeof countries>)
                    .filter(countryCode => CONTINENT_BY_COUNTRY_CODE[countryCode] === continentName)
                    .map(countryCode => (
                      <div
                        key={countryCode}
                        className={styles.countryButton}
                        onClick={() => setSelectedCountry(countryCode)}
                      >
                        <FiMapPin />
                        <span className={styles.countryName}>
                          {COUNTRY_NAME_BY_COUNTRY_CODE[countryCode]}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.contactsCardWrapper}>
            {!!selectedCountry && !!selectedCountryData && (
              <Portal>
                <div className={styles.overlay} onClick={() => setSelectedCountry(null)}>
                  <div
                    className={styles.countryContactsCardWrapper}
                    onClick={e => e.stopPropagation()}
                  >
                    <CountryContactsCard
                      countryName={COUNTRY_NAME_BY_COUNTRY_CODE[selectedCountry]}
                      countryPresenceType={selectedCountryData.presenceType}
                      contacts={selectedCountryData.contacts}
                      onCloseClick={() => setSelectedCountry(null)}
                    ></CountryContactsCard>
                  </div>
                </div>
              </Portal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorldwideModule;
