import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import * as styles from './index.module.scss';
import { RawPortableText } from '../../../types';
import {
  EmbeddedImageSerializer,
  GenericLinkSerializer,
  PageLinkSerializer,
} from '../../../serializers';

export const RichTextModuleFragment = graphql`
  fragment RichTextModule on SanityRichTextModule {
    title {
      ...LocaleStringFragment
    }
    body {
      ...LocaleRichTextPortableText
    }
  }
`;

export interface RichTextModuleProps {
  title?: string;
  body: RawPortableText;
}

const RichTextModule = ({ title, body }: RichTextModuleProps): React.ReactElement => {
  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <BlockContent
        renderContainerOnSingleChild
        className={styles.paragraph}
        blocks={body}
        serializers={{
          marks: {
            genericLink: GenericLinkSerializer,
            pageLink: PageLinkSerializer,
          },
          types: {
            embeddedImage: EmbeddedImageSerializer,
          },
        }}
      />
    </div>
  );
};

export default RichTextModule;
