import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { clsx } from '../utils/utils';

import * as styles from './Accordion.module.scss';

interface AccordionProps {
  className?: string;
  title: string;
  subtitle?: string;
  children: React.ReactElement;
  startsOpen?: boolean;
  color?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

const Accordion = ({
  className,
  title,
  subtitle,
  children,
  startsOpen,
  color,
  onOpen,
  onClose,
}: AccordionProps): React.ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(!!startsOpen);
  return (
    <section
      className={clsx(
        styles.accordion + (isAccordionOpen ? ' ' + styles.accordionOpen : ''),
        className,
      )}
    >
      <div className={styles.accordionBox}>
        <div className={styles.titleBoxWithColorBar}>
          <div className={styles.colorBar} style={{ backgroundColor: color }}></div>

          <div
            className={styles.titleBox}
            onClick={() => {
              setIsAccordionOpen(!isAccordionOpen);
              if (isAccordionOpen) {
                if (onClose) {
                  onClose();
                }
              } else {
                if (onOpen) {
                  onOpen();
                }
              }
            }}
          >
            <div className={styles.titleAndSubtitle}>
              <span className={styles.title}>{title}</span>
              {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
            </div>
            <FaChevronDown className={styles.icon}></FaChevronDown>
          </div>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </section>
  );
};

export default Accordion;
