import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import * as styles from './index.module.scss';
import { RawPortableText } from '../../../types';
import { GenericLinkSerializer, PageLinkSerializer } from '../../../serializers';

export const ParagraphModuleFragment = graphql`
  fragment ParagraphModule on SanityParagraphModule {
    title {
      ...LocaleStringFragment
    }
    textAlignment
    portableText {
      ...LocaleSimplePortableTextWithLinksAndLists
    }
  }
`;

export interface ParagraphModuleProps {
  title?: string;
  textAlignment: string;
  portableText: RawPortableText;
}

const ParagraphModule = ({
  title,
  textAlignment,
  portableText,
}: ParagraphModuleProps): React.ReactElement => {
  return (
    <div className={styles.moduleRoot}>
      <div
        className={styles.container + (textAlignment === 'left' ? ' ' + styles.leftAlignment : '')}
      >
        {title && <h3 className={styles.title}>{title}</h3>}

        <BlockContent
          className={styles.paragraphWrapper}
          renderContainerOnSingleChild
          blocks={portableText}
          serializers={{
            marks: {
              genericLink: GenericLinkSerializer,
              pageLink: PageLinkSerializer,
            },
          }}
        />
      </div>
    </div>
  );
};

export default ParagraphModule;
