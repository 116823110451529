import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as styles from './index.module.scss';

export const GoogleMapModuleFragment = graphql`
  fragment GoogleMapModule on SanityGoogleMapModule {
    title {
      ...LocaleStringFragment
    }
    address {
      ...LocaleTextFragment
    }
  }
`;

export interface GoogleMapModuleProps {
  title?: string;
  address: string;
}

interface LocalizedQueryData {
  site: {
    siteMetadata: {
      googleMapsAPIKey: string;
    };
  };
}

const GoogleMapModule = ({ title, address }: GoogleMapModuleProps): React.ReactElement => {
  const { site }: LocalizedQueryData = useStaticQuery(graphql`
    query GoogleMapsApiKey {
      site {
        siteMetadata {
          googleMapsAPIKey
        }
      }
    }
  `);

  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.content}>
        <div>
          <iframe
            height="400"
            frameBorder="0"
            style={{ width: '100%', border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?q=${address}&key=${site.siteMetadata.googleMapsAPIKey}`}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleMapModule;
