// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var bottomTimelineEndingPoint = "index-module--bottomTimelineEndingPoint--pB2IP";
export var container = "index-module--container--GNRRh";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var entryRow = "index-module--entryRow--atHHX";
export var error = "#f66a83";
export var firstEntry = "index-module--firstEntry--etGq4";
export var green = "#3dae2b";
export var grey = "#828382";
export var left = "index-module--left--TQ677";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var line = "index-module--line--gMyir";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var text = "index-module--text--UDBwe";
export var timeEntriesContainer = "index-module--timeEntriesContainer--DMNBa";
export var timeEntry = "index-module--timeEntry--Bfgnd";
export var timelineLeftLine = "index-module--timelineLeftLine--+1Ist";
export var timelineRightLine = "index-module--timelineRightLine--M7hNU";
export var topTimelineEndingPoint = "index-module--topTimelineEndingPoint--MTWxL";
export var warning = "#ffeda0";
export var white = "#fff";
export var year = "index-module--year--F7cnw";
export var yearBlock = "index-module--yearBlock--DL0OS";
export var yearContainer = "index-module--yearContainer--3PZhV";
export var yellow = "#fbc900";