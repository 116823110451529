import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import * as styles from './index.module.scss';

import { COLORS } from '../../../constants';

import NumberCounter from './../../NumberCounter';

import ActiveFormulationsSvg from '../../../assets/icons/ActiveFormulations.inline.svg';
import HumanResources from '../../../assets/icons/HumanResources.inline.svg';
import MarketPresence from '../../../assets/icons/MarketShare.inline.svg';
import PortfolioDevelopment from '../../../assets/icons/PortfolioDevelopment.inline.svg';
import Production from '../../../assets/icons/Production.inline.svg';
import ProductsRegistered from '../../../assets/icons/ProductsRegistered.inline.svg';
import RevenueInvested from '../../../assets/icons/RevenueInvested.inline.svg';
import Revenue from '../../../assets/icons/Revenue.inline.svg';
import ThousandTonsProduced from '../../../assets/icons/ThousandTonsProduced.inline.svg';
import GlobalFootprint from '../../../assets/icons/GlobalFootprint.inline.svg';

import { useVisible } from 'react-hooks-visible';

export const NumbersModuleFragment = graphql`
  fragment NumbersModule on SanityNumbersModule {
    title {
      ...LocaleStringFragment
    }
    numbers {
      icon
      title {
        ...LocaleStringFragment
      }
      value
      text {
        ...LocaleTextFragment
      }
      year
    }
    baseColor {
      value
    }
  }
`;

export interface NumbersModuleProps {
  title?: string;
  numbers: Array<
    | {
        icon: string;
        title: string;
        value: string;
        text: string;
        year?: string;
      }
    | {
        icon: string;
        title: string;
        value: string;
        text?: string;
        year: string;
      }
  >;
  baseColor: {
    value: typeof COLORS.blue | typeof COLORS.green;
  };
}

function renderIcon(icon: string, iconColor: string) {
  if (icon === 'active_formulations') {
    // return <img className={styles.icon} src={ActiveFormulationsSvg} alt="Active Formulations" />;
    return (
      <ActiveFormulationsSvg
        className={styles.icon}
        style={{ stroke: iconColor, fill: iconColor }}
      />
    );
  } else if (icon === 'human_resources') {
    return (
      <HumanResources className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />
    );
  } else if (icon === 'market_presence') {
    return (
      <MarketPresence className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />
    );
  } else if (icon === 'portfolio_development') {
    return (
      <PortfolioDevelopment
        className={styles.icon}
        style={{ stroke: iconColor, fill: iconColor }}
      />
    );
  } else if (icon === 'production') {
    return <Production className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />;
  } else if (icon === 'products_registered') {
    return (
      <ProductsRegistered className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />
    );
  } else if (icon === 'revenue') {
    return (
      <RevenueInvested className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />
    );
  } else if (icon === 'revenue_invested') {
    return <Revenue className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />;
  } else if (icon === 'thousand_tons_produced') {
    return (
      <ThousandTonsProduced
        className={styles.icon}
        style={{ stroke: iconColor, fill: iconColor }}
      />
    );
  } else if (icon === 'global_footprint') {
    return (
      <GlobalFootprint className={styles.icon} style={{ stroke: iconColor, fill: iconColor }} />
    );
  } else {
    throw new Error('Got unrecognized icon: ' + icon);
  }
}

const NumbersModule = ({ title, numbers, baseColor }: NumbersModuleProps): React.ReactElement => {
  const [hasCountUpStarted, setHasCountUpStarted] = useState(false);
  const [sectionRef, isSectionVisible] = useVisible<HTMLDivElement, boolean>(vi => vi > 0.2);

  useEffect(() => {
    if (isSectionVisible && !hasCountUpStarted) {
      setHasCountUpStarted(true);
    }
  }, [isSectionVisible]);

  const numbersPerRowClass = {
    3: styles.numbersPerRow3,
    4: styles.numbersPerRow4,
    5: styles.numbersPerRow5,
    6: styles.numbersPerRow6,
  }[numbers.length > 6 ? 6 : numbers.length];

  return (
    <section ref={sectionRef} className={styles.moduleRoot}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.container}>
        {numbers.map(({ icon, title, value, text, year }, numberIndex) => {
          return (
            <div key={numberIndex} className={styles.numberContainer + ' ' + numbersPerRowClass}>
              <div className={styles.iconAndNumberContainer}>
                {icon && renderIcon(icon, baseColor.value)}
                <div className={styles.value} style={{ borderColor: baseColor.value }}>
                  <NumberCounter
                    numberStr={value}
                    hasCountUpStarted={hasCountUpStarted}
                    className={styles.number}
                  ></NumberCounter>
                </div>
              </div>
              <div className={styles.titleContainer} style={{ borderColor: baseColor.value }}>
                <h4 className={styles.numberBlockTitle}>{title}</h4>
              </div>
              {text && <span className={styles.numberBlockText}>{text}</span>}
              {year && <p className={styles.year}>{year}</p>}
            </div>
          );
        })}
      </div>
      {/* </div> */}
    </section>
  );
};

export default NumbersModule;
