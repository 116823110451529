// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var arrow = "index-module--arrow--PdzGU";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--qK3m2";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var dropDownList = "index-module--dropDownList--iEQHW";
export var error = "#f66a83";
export var formControl = "index-module--formControl--zh0Ni";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "index-module--icon--AILWf";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var menuItem = "index-module--menuItem--Cwhsa";
export var moduleRoot = "index-module--moduleRoot--ZJ6XW";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var paragraph = "index-module--paragraph--bsItt";
export var placeholder = "index-module--placeholder--b9mlB";
export var root = "index-module--root--+kgZi";
export var select = "index-module--select--G6moD";
export var selectButton = "index-module--selectButton--w0fRJ";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";