// extracted by mini-css-extract-plugin
export var active = "index-module--active--JmdYw";
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var cards = "index-module--cards--X6lG6";
export var cardsContainer = "index-module--cardsContainer--AByd0";
export var colorBar = "index-module--colorBar--pn8Y3";
export var container = "index-module--container--NtTAJ";
export var content = "index-module--content--zkHNn";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var footerLink = "index-module--footerLink--UPyg+";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var mainText = "index-module--mainText--2+NwG";
export var moduleRoot = "index-module--moduleRoot--BJuZp";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var tab = "index-module--tab--bk0LO";
export var tabs = "index-module--tabs--EKHPv";
export var tabsDesktopContainer = "index-module--tabsDesktopContainer--3qnGk";
export var tabsMobile = "index-module--tabsMobile--jXF56";
export var title = "index-module--title--60URl";
export var titleAndSubtitle = "index-module--titleAndSubtitle--6+fyb";
export var titleParagraph = "index-module--titleParagraph--AkoVu";
export var transparentBar = "index-module--transparentBar--ZxQA6";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";