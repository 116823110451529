import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { FaChevronDown, FaFilter } from 'react-icons/fa';

import NewsCard from '../../NewsCard';
import * as styles from './index.module.scss';
import { removeItem, slugify, sortBy, uniq } from '../../../utils/utils';

import { useLocalization, usePagination, useUpdateUrlFromFilters } from '../../../utils/hooks';
import { useTranslation } from 'react-i18next';

export const NewsImageFragment = graphql`
  fragment NewsCardImageFragment on SanityLocaleImage {
    __typename
    en {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          height: 320
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pt {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          height: 320
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    es {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          height: 320
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    fr {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          height: 320
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    it {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          height: 320
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ro {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          height: 320
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }

  fragment NewsArticleImageFragment on SanityLocaleImage {
    __typename
    en {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          height: 780
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    pt {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          height: 780
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    es {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          height: 780
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    fr {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          height: 780
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    it {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          height: 780
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ro {
      asset {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          height: 780
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
export const NewsModuleFragment = graphql`
  fragment NewsModule on SanityNewsModule {
    newsContentTypes {
      _id
    }
    showOnlyAFewLatestArticles
    numberOfArticlesToShow
  }
`;

export interface NewsModuleProps {
  newsContentTypes: Array<{
    _id: string;
  }>;
  showOnlyAFewLatestArticles?: boolean;
  numberOfArticlesToShow: number;
}

interface LocalizedQueryData {
  allSanityNewsArticle: {
    nodes: Array<{
      title: string;
      slug: {
        current: string;
      };
      image: {
        asset: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      date: string;
      extract: string;
      category: {
        title: string;
      };
      contentType: {
        _id: string;
        title: string;
        slug: {
          current: string;
        };
      };
    }>;
  };
  allSanityNewsCategory: {
    nodes: Array<{
      title: string;
    }>;
  };
  allSanityNewsContentType: {
    nodes: Array<{
      title: string;
    }>;
  };
}

const DEFAULT_NEWS_PER_PAGE = 3;

const NewsModule = ({
  newsContentTypes: moduleContentTypes,
  showOnlyAFewLatestArticles,
  numberOfArticlesToShow,
}: NewsModuleProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      allSanityNewsArticle(sort: { fields: date, order: DESC }) {
        nodes {
          title {
            ...LocaleStringFragment
          }
          slug {
            current
          }
          image {
            ...NewsCardImageFragment
          }
          date
          extract {
            ...LocaleTextFragment
          }
          category {
            title {
              ...LocaleStringFragment
            }
          }
          contentType {
            _id
            title {
              ...LocaleStringFragment
            }
            slug {
              current
            }
          }
        }
      }
      allSanityNewsCategory {
        nodes {
          title {
            ...LocaleStringFragment
          }
        }
      }
      allSanityNewsContentType {
        nodes {
          title {
            ...LocaleStringFragment
          }
        }
      }
    }
  `);

  const newsPerPage =
    showOnlyAFewLatestArticles && numberOfArticlesToShow
      ? numberOfArticlesToShow
      : DEFAULT_NEWS_PER_PAGE;

  const { i18n } = useTranslation();
  const dataWithLangFilteredNewsArticles = {
    ...data,
    allSanityNewsArticle: {
      ...data.allSanityNewsArticle,
      nodes: data.allSanityNewsArticle.nodes.filter(
        newsArticle =>
          newsArticle.title[i18n.language] !== null && newsArticle.extract[i18n.language] !== null,
      ),
    },
  };

  const { t, localizedData } = useLocalization<LocalizedQueryData>(
    dataWithLangFilteredNewsArticles,
  );
  const { nodes: newsCategories } = localizedData.allSanityNewsCategory;
  const { nodes: newsContentTypes } = localizedData.allSanityNewsContentType;

  const { nodes: newsArticles } = localizedData.allSanityNewsArticle;

  const newsArticlesFilteredByModuleContentTypes =
    moduleContentTypes.length > 0
      ? newsArticles.filter(newsArticle =>
          moduleContentTypes.map(type => type._id).includes(newsArticle.contentType._id),
        )
      : newsArticles;

  const existingCategoriesTitles = uniq(
    newsArticlesFilteredByModuleContentTypes.map(newsArticle => newsArticle.category.title),
  );
  const existingContentTypesTitles = uniq(
    newsArticlesFilteredByModuleContentTypes.map(newsArticle => newsArticle.contentType.title),
  );

  const existingCategories = newsCategories.filter(newsCategory =>
    existingCategoriesTitles.includes(newsCategory.title),
  );
  const existingContentTypes = newsContentTypes.filter(newsContentType =>
    existingContentTypesTitles.includes(newsContentType.title),
  );

  const [selectedCategories, setSelectedCategories] = useState(existingCategoriesTitles);
  const [selectedContentTypes, setSelectedContentTypes] = useState(existingContentTypesTitles);

  const filteredNews = newsArticlesFilteredByModuleContentTypes
    .filter(newsArticle => selectedCategories.includes(newsArticle.category.title))
    .filter(newsArticle => selectedContentTypes.includes(newsArticle.contentType.title));

  const {
    currentPage,
    currentPageItems: currentPageNews,
    getPageUrlPart,
    resetPagination,
    renderPagination,
  } = usePagination(filteredNews, newsPerPage);

  // Update url on filters state change
  useUpdateUrlFromFilters(() => [getPageUrlPart()], [currentPage]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {!showOnlyAFewLatestArticles &&
          (existingContentTypes.length > 1 || existingCategories.length > 1) && (
            <div className={styles.filtersBar}>
              <div
                className={
                  styles.filterLabelContainer +
                  ' ' +
                  (isAccordionOpen ? styles.accordionOpen : styles.accordionClosed)
                }
              >
                <div>
                  <FaFilter className={styles.filterIcon}></FaFilter>
                  <span className={styles.filterLabel}>
                    {t('module_news.filters.main_label', 'Filters')}
                  </span>
                </div>
                <FaChevronDown
                  onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                  className={styles.accordionIcon}
                ></FaChevronDown>
              </div>
              <div className={styles.filtersContainer}>
                {existingContentTypes.length > 1 && (
                  <>
                    <div className={styles.blueBorder}></div>
                    <div className={styles.filterContainer}>
                      <span className={styles.filterName}>
                        {t('module_news.filters.content_type_label', 'Content Type')}
                      </span>
                      <div className={styles.contentTypes}>
                        {existingContentTypes.map((contentType, index) => {
                          return (
                            <div key={index} className={styles.checkboxContainer}>
                              <label
                                htmlFor={'contentType-' + slugify(contentType.title)}
                                className={styles.checkboxLabel}
                              >
                                <input
                                  type="checkbox"
                                  id={'contentType-' + slugify(contentType.title)}
                                  name={contentType.title}
                                  className={styles.checkbox}
                                  checked={
                                    !!selectedContentTypes.find(type => type === contentType.title)
                                  }
                                  onChange={e => {
                                    resetPagination();
                                    setSelectedContentTypes(
                                      e.target.checked
                                        ? [...selectedContentTypes, contentType.title]
                                        : removeItem(selectedContentTypes, contentType.title),
                                    );
                                  }}
                                ></input>
                                <span className={styles.fakeCheckbox}></span>
                                <span className={styles.label}>{contentType.title}</span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <span className={styles.separator}></span>
                  </>
                )}

                {existingCategories.length > 1 && (
                  <div className={styles.filterContainer}>
                    <span className={styles.filterName}>
                      {t('module_news.filters.category_label', 'Category')}
                    </span>
                    <div className={styles.category}>
                      {existingCategories.map((category, index) => {
                        return (
                          <div key={index} className={styles.checkboxContainer}>
                            <label
                              htmlFor={'category-' + slugify(category.title)}
                              className={styles.checkboxLabel}
                            >
                              <input
                                type="checkbox"
                                id={'category-' + slugify(category.title)}
                                name={category.title}
                                className={styles.checkbox}
                                checked={!!selectedCategories.find(cat => cat === category.title)}
                                onChange={e => {
                                  resetPagination();
                                  setSelectedCategories(
                                    e.target.checked
                                      ? [...selectedCategories, category.title]
                                      : removeItem(selectedCategories, category.title),
                                  );
                                }}
                              ></input>
                              <span className={styles.fakeCheckbox}></span>
                              <span className={styles.label}> {category.title}</span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

        <div className={styles.newsContainer}>
          {currentPageNews.length === 0 ? (
            <div className={styles.noNewsResultsText}>
              <FontAwesomeIcon icon="info-circle" className={styles.infoIcon} />
              {t(
                'module_news.no_news_text',
                'No news found for selected categories and content types.',
              )}
            </div>
          ) : (
            currentPageNews.map((newsArticle, index) => {
              return (
                <NewsCard
                  className={styles.card}
                  key={index}
                  title={newsArticle.title}
                  url={`/${
                    newsArticle.contentType.slug
                      ? newsArticle.contentType.slug.current.trim().replace(/^\/+|\/+$/g, '')
                      : `media/news`
                  }/${newsArticle.slug.current}/`}
                  image={newsArticle.image.asset.gatsbyImageData}
                  date={newsArticle.date}
                  extract={newsArticle.extract}
                  category={newsArticle.category}
                  contentType={newsArticle.contentType}
                ></NewsCard>
              );
            })
          )}
        </div>
      </div>
      {!showOnlyAFewLatestArticles && (
        <div className={styles.paginationContainer}>{renderPagination()}</div>
      )}
    </div>
  );
};

export default NewsModule;
