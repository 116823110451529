import React from 'react';
import { Link } from 'gatsby';
import { FaChevronRight, FaTag } from 'react-icons/fa';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import GenericLink from './GenericLink';
import { useTranslation } from 'react-i18next';

import * as styles from './NewsCard.module.scss';

export interface NewsCardProps {
  title: string;
  url: string;
  image: IGatsbyImageData;
  date: string;
  extract: string;
  category: {
    title: string;
  };
  contentType: {
    title: string;
  };
  className: string;
}

const NewsCard = ({
  title,
  url,
  image,
  date,
  extract,
  category,
  contentType,
  className,
}: NewsCardProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <GenericLink noStyle className={styles.cardContainer + ' ' + className} to={url}>
      <div className={styles.imageContainerWrapper}>
        <GatsbyImage
          alt={''}
          className={styles.imageContainer}
          imgClassName={styles.image}
          image={image}
        ></GatsbyImage>
        <div className={styles.categoryContentTypeBar}>
          <FaTag className={styles.categoryIcon}></FaTag>
          <span className={styles.categoryTitle + ' titleParagraph'}>{contentType.title}</span>
          <span className={styles.categoryTitle + ' titleParagraph'}>{category.title}</span>
        </div>
      </div>

      <div className={styles.content}>
        <div>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.date + ' titleParagraph'}>{date}</p>
          <p className={styles.extract}>{extract}</p>
        </div>
        <GenericLink noStyle className={'titleParagraph ' + styles.link} to={url}>
          {t('news_card.read_more_link', 'Read more')}
          <FaChevronRight className={styles.linkIcon}></FaChevronRight>
        </GenericLink>
      </div>
    </GenericLink>
  );
};

export default NewsCard;
