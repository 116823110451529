import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useVisible } from 'react-hooks-visible';

import * as styles from './index.module.scss';
import { COLORS } from '../../../constants';

import NumberCounter from '../../NumberCounter';

export const GroupedNumbersModuleFragment = graphql`
  fragment GroupedNumbersModule on SanityGroupedNumbersModule {
    title {
      ...LocaleStringFragment
    }
    firstGroup {
      title {
        ...LocaleStringFragment
      }
      firstNumber {
        value
        title {
          ...LocaleStringFragment
        }
        text {
          ...LocaleTextFragment
        }
      }
      secondNumber {
        value
        title {
          ...LocaleStringFragment
        }
        text {
          ...LocaleTextFragment
        }
      }
    }
    secondGroup {
      title {
        ...LocaleStringFragment
      }
      firstNumber {
        value
        title {
          ...LocaleStringFragment
        }
        text {
          ...LocaleTextFragment
        }
      }
      secondNumber {
        value
        title {
          ...LocaleStringFragment
        }
        text {
          ...LocaleTextFragment
        }
      }
    }
  }
`;

export interface GroupedNumbersModuleProps {
  title?: string;
  firstGroup: {
    title: string;
    firstNumber: {
      value: string;
      title: string;
      text: string;
    };
    secondNumber: {
      value: string;
      title: string;
      text: string;
    };
  };
  secondGroup: {
    title: string;
    firstNumber: {
      value: string;
      title: string;
      text: string;
    };
    secondNumber: {
      value: string;
      title: string;
      text: string;
    };
  };
}

const GroupedNumbersModule = ({
  title,
  firstGroup,
  secondGroup,
}: GroupedNumbersModuleProps): React.ReactElement => {
  const [hasCountUpStarted, setHasCountUpStarted] = useState(false);
  const [sectionRef, isSectionVisible] = useVisible<HTMLDivElement, boolean>(vi => vi > 0.2);

  useEffect(() => {
    if (isSectionVisible && !hasCountUpStarted) {
      setHasCountUpStarted(true);
    }
  }, [isSectionVisible]);

  return (
    <div ref={sectionRef} className={styles.moduleRoot}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.container}>
        {[
          { group: firstGroup, color: COLORS.orange },
          { group: secondGroup, color: COLORS.blue },
        ].map(({ group, color }, index) => (
          <div key={index} className={styles.group}>
            <h4 className={styles.groupTitle} style={{ borderColor: color }}>
              {group.title}
            </h4>
            <div className={styles.numbersContainer}>
              {[group.firstNumber, group.secondNumber].map((number, numberIndex) => (
                <div key={numberIndex} className={styles.numberContainer}>
                  <div className={styles.value} style={{ borderColor: color }}>
                    <NumberCounter
                      className={styles.number}
                      numberStr={number.value}
                      hasCountUpStarted={hasCountUpStarted}
                    ></NumberCounter>
                  </div>
                  <div className={styles.titleContainer} style={{ borderColor: color }}>
                    <h4 className={styles.title}>{number.title}</h4>
                  </div>
                  <span className={styles.text}>{number.text}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupedNumbersModule;
