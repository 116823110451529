import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as styles from './index.module.scss';
import { useLocalization } from '../../../utils/hooks';

export const GroupedContactsModuleFragment = graphql`
  fragment GroupedContactsModule on SanityGroupedContactsModule {
    label
  }
`;

export interface GroupedContactsModuleProps {
  label: string;
}

interface LocalizedQueryData {
  sanityGroupedContacts: {
    contactBlocks: Array<{
      title: string;
      contactGroups: Array<{
        title: string;
        contacts: Array<{
          department: string;
          name: string;
          phoneNumber: string;
          email: string;
        }>;
      }>;
    }>;
  } | null;
}

const GroupedContactsModule = ({ label }: GroupedContactsModuleProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityGroupedContacts(_id: { eq: "pressContacts-singleton" }) {
        contactBlocks {
          title {
            ...LocaleStringFragment
          }
          contactGroups {
            title {
              ...LocaleStringFragment
            }
            contacts {
              department {
                ...LocaleStringFragment
              }
              name {
                ...LocaleStringFragment
              }
              phoneNumber
              email
            }
          }
        }
      }
    }
  `);

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const { contactBlocks } = localizedData.sanityGroupedContacts || {};

  return (
    <div className={styles.moduleRoot}>
      {/* <div className={styles.contactsContainer}> */}
      {contactBlocks &&
        contactBlocks.length > 0 &&
        contactBlocks.map((contactBlock, index) => {
          return (
            <div key={index} className={styles.contactsContainer}>
              <h3>{contactBlock.title}</h3>
              <div className={styles.contactGroup}>
                {contactBlock.contactGroups.map((contactGroup, index) => {
                  return (
                    <div key={index} className={styles.contactBlock}>
                      <h4 className={styles.contactBlockTitle}>{contactGroup.title}</h4>
                      {contactGroup.contacts.map((contact, index) => {
                        return (
                          <div key={index} className={styles.contact}>
                            {!!contact.department && (
                              <span className={styles.department}>{contact.department}</span>
                            )}
                            {!!contact.name && (
                              <span className={styles.contactName}>{contact.name}</span>
                            )}
                            {!!contact.phoneNumber && (
                              <a className={styles.contactLink} href={'tel:' + contact.phoneNumber}>
                                {contact.phoneNumber}
                              </a>
                            )}
                            {!!contact.email && (
                              <a className={styles.contactLink} href={'mailto:' + contact.email}>
                                {contact.email}
                              </a>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
    // </div>
  );
};

export default GroupedContactsModule;
