// extracted by mini-css-extract-plugin
export var address = "CountryContactsCard-module--address--NrF9J";
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var closeButton = "CountryContactsCard-module--closeButton--Kkrhh";
export var contactGroup = "CountryContactsCard-module--contactGroup--My7VI";
export var contactGroupTitle = "CountryContactsCard-module--contactGroupTitle--fpX0e";
export var coolGrey = "#e1e2e0";
export var countryContactsCard = "CountryContactsCard-module--countryContactsCard--WmVK0";
export var countryContactsContainer = "CountryContactsCard-module--countryContactsContainer--SVqNI";
export var countryIcon = "CountryContactsCard-module--countryIcon--1L8c8";
export var countryMainInfo = "CountryContactsCard-module--countryMainInfo--3H-Pi";
export var countryMainInfoContainer = "CountryContactsCard-module--countryMainInfoContainer--jpVD8";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var footerLink = "CountryContactsCard-module--footerLink--TrPhf";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var titleParagraph = "CountryContactsCard-module--titleParagraph--1vNaz";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";