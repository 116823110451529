import React from 'react';
import * as styles from './CountryContactsCard.module.scss';
import GenericLink from './GenericLink';
import { FaTimes } from 'react-icons/fa';

import headOffice from '../assets/icons/headOffice.svg';
import office from '../assets/icons/office.svg';
import commercialPresence from '../assets/icons/commercialPresence.svg';
import { useTranslation } from 'react-i18next';

export type CountryPresenceType = 'commercial-presence' | 'office' | 'head-office';

export interface CountryContactsCardProps {
  countryName: string;
  countryPresenceType: CountryPresenceType;
  contacts: Array<{
    name: string;
    address?: string;
    phoneNumber?: string;
    website?: string;
    email?: string;
  }>;
  onCloseClick?: () => void;
}

const CountryContactsCard = ({
  countryName,
  countryPresenceType,
  contacts,
  onCloseClick,
}: CountryContactsCardProps): React.ReactElement => {
  const { t } = useTranslation();

  const CountryPresenceDescriptionByType: Record<CountryPresenceType, string> = {
    'commercial-presence': t(
      'module_worldwide.presence.commercial_presence',
      'Commercial Presence',
    ),
    office: t('module_worldwide.presence.office', 'Office'),
    'head-office': t('module_worldwide.presence.head_office', 'Head Office'),
  };

  function renderCountryIcon(countryPresenceType: CountryPresenceType) {
    if (countryPresenceType === 'head-office') {
      return <img className={styles.icon} src={headOffice} alt="" />;
    } else if (countryPresenceType === 'office') {
      return <img className={styles.icon} src={office} alt="" />;
    } else if (countryPresenceType === 'commercial-presence') {
      return <img className={styles.icon} src={commercialPresence} alt="" />;
    } else {
      throw new Error('Unrecognized icon');
    }
  }

  return (
    <div className={styles.countryContactsCard}>
      {onCloseClick && <FaTimes className={styles.closeButton} onClick={onCloseClick} />}
      <div className={styles.countryMainInfoContainer}>
        <div className={styles.countryIcon}>{renderCountryIcon(countryPresenceType)}</div>
        <div className={styles.countryMainInfo}>
          <h3>{countryName}</h3>
          <span>{CountryPresenceDescriptionByType[countryPresenceType]}</span>
        </div>
      </div>
      <div className={styles.countryContactsContainer}>
        {contacts.map((contact, i) => (
          <div key={i} className={styles.contactGroup}>
            <h4 className={styles.contactGroupTitle}>{contact.name}</h4>
            {contact.address && (
              <GenericLink
                noExternalUrlIcon
                to={'https://www.google.com/maps/search/?api=1&query=' + encodeURI(contact.address)}
                className={styles.address}
              >
                {contact.address}
              </GenericLink>
            )}
            {contact.phoneNumber && (
              <GenericLink
                className={styles.phoneNumber}
                noExternalUrlIcon
                to={'tel:' + contact.phoneNumber}
              >
                {contact.phoneNumber}
              </GenericLink>
            )}
            {contact.website && (
              <GenericLink className={styles.website} noExternalUrlIcon to={contact.website}>
                {contact.website}
              </GenericLink>
            )}
            {contact.email && (
              <GenericLink
                className={styles.email}
                noExternalUrlIcon
                to={'mailto:' + contact.email}
              >
                {contact.email}
              </GenericLink>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountryContactsCard;
