import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import EventCard from '../../EventCard';
import { sortBy } from '../../../utils/utils';

import * as styles from './index.module.scss';
import { useLocalization } from '../../../utils/hooks';

export const EventModuleFragment = graphql`
  fragment EventModule on SanityEventsModule {
    label
  }
`;

export interface EventModuleProps {
  label: string;
}

interface LocalizedQueryData {
  allSanityEvent: {
    nodes: Array<{
      title: string;
      description: string;
      url: string;
      language: string;
      location: string;
      image: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
        };
      };
      startDate: string;
      endDate: string;
    }>;
  };
}

function parseDate(dateStr: string): Date {
  return new Date(dateStr + 'T00:00:00Z');
}

const EventsModule = ({ label }: EventModuleProps): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      allSanityEvent {
        nodes {
          title {
            ...LocaleStringFragment
          }
          description {
            ...LocaleTextFragment
          }
          url
          language {
            ...LocaleStringFragment
          }
          location {
            ...LocaleStringFragment
          }
          image {
            asset {
              url
              gatsbyImageData(
                layout: CONSTRAINED
                width: 350
                height: 350
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          startDate
          endDate
        }
      }
    }
  `);

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const { nodes: eventsArticles } = localizedData.allSanityEvent;
  const allEventsSorted = sortBy(
    eventsArticles,
    event => parseDate(event.startDate || event.endDate),
    'asc',
  );

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {allEventsSorted.length === 0 ? (
          <div className={styles.noEventsResultsText}>
            <FontAwesomeIcon icon="info-circle" className={styles.infoIcon} />
            {t('module_event.no_events_results_text', 'No scheduled events.')}
          </div>
        ) : (
          <div className={styles.eventsContainer}>
            {allEventsSorted.map((event, index) => {
              return (
                <EventCard
                  key={index}
                  title={event.title}
                  description={event.description}
                  url={event.url}
                  language={event.language}
                  location={event.location}
                  image={event.image}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  className={styles.eventCard}
                ></EventCard>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsModule;
