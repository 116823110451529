// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var author = "index-module--author--XaXkf";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var containedWidth = "index-module--containedWidth--OsLqp";
export var content = "index-module--content--vSi-I";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var facePhotoContainer = "index-module--facePhotoContainer--qYbC5";
export var facePhotoImageContainer = "index-module--facePhotoImageContainer--i2V+-";
export var genericPhotoContainer = "index-module--genericPhotoContainer--WsjoA";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "index-module--image--LJFiz";
export var imageContainer = "index-module--imageContainer--N2F5C";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--ZD0qi";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var quoteContainer = "index-module--quoteContainer--dxmN-";
export var quoteContent = "index-module--quoteContent--j2EjO";
export var quoteIcon = "index-module--quoteIcon--XWLtv";
export var quoteText = "index-module--quoteText--76a8o";
export var reverse = "index-module--reverse--cXZi0";
export var success = "#1ebc7b";
export var title = "index-module--title--64PxA";
export var warning = "#ffeda0";
export var white = "#fff";
export var withBackground = "index-module--withBackground--Nz82A";
export var yellow = "#fbc900";