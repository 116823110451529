import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as styles from './index.module.scss';
import FileRow from '../../FileRow';
import { OpenTermsModalContext } from '../../../contexts/OpenTermsModalContext';
import { sortBy } from '../../../utils/utils';
import { useLocalization } from '../../../utils/hooks';

export const ResourceFilesModuleFragment = graphql`
  fragment ResourceFilesModule on SanityResourceFilesModule {
    title {
      ...LocaleStringFragment
    }
  }
`;

export interface ResourceFilesModuleProps {
  title: string;
}

interface LocalizedQueryData {
  allSanityResourceFile: {
    nodes: Array<{
      name: string;
      description: string;
      file: {
        asset: {
          url: string;
          size: number;
        };
      };
      priority: number | null;
    }>;
  };
}

const ResourceFilesModule = ({ title }: ResourceFilesModuleProps): React.ReactElement => {
  const openModalIfTermsNotAccepted = useContext(OpenTermsModalContext);
  const data = useStaticQuery(graphql`
    {
      allSanityResourceFile {
        nodes {
          name {
            ...LocaleStringFragment
          }
          description {
            ...LocaleTextFragment
          }
          file {
            ...LocaleFileFragment
          }
          priority
        }
      }
    }
  `);

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const { nodes: resourceFiles } = localizedData.allSanityResourceFile;
  const allResourceFilesSorted = sortBy(
    resourceFiles,
    resourceFile =>
      resourceFile.priority !== null ? resourceFile.priority : Number.NEGATIVE_INFINITY,
    'desc',
  );

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.resourceFilesContainer}>
        <h3 className={styles.title}>{title}</h3>
        {allResourceFilesSorted.map(
          (resourceFile, index) =>
            !!(resourceFile.file && resourceFile.file.asset) && (
              <FileRow
                key={index}
                title={resourceFile.name}
                description={resourceFile.description}
                size={resourceFile.file.asset.size}
                url={resourceFile.file.asset.url}
                onDownloadClick={openModalIfTermsNotAccepted}
              ></FileRow>
            ),
        )}
      </div>
    </div>
  );
};

export default ResourceFilesModule;
