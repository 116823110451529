import React from 'react';
import { graphql } from 'gatsby';

import * as styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormHelperText } from '@material-ui/core';

import { useFormField, useForm } from '../../../utils/forms';
import TextField from '../../TextField';
import CheckboxField from '../../CheckboxField';
import GenericLink from '../../GenericLink';
import { Trans, useTranslation } from 'react-i18next';
import { withDataLayer } from '../../../utils/utils';

const MAX_MESSAGE_CHARACTERS = 500;

export const ContactFormModuleFragment = graphql`
  fragment ContactFormModule on SanityContactFormModule {
    title {
      ...LocaleStringFragment
    }
    reference {
      slug {
        current
      }
    }
  }
`;

export interface ContactFormModuleProps {
  title?: string;
  reference: {
    slug: {
      current: string;
    };
  };
}

const ContactFormModule = ({ title, reference }: ContactFormModuleProps): React.ReactElement => {
  const { t } = useTranslation();

  const fieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    phone: useFormField<string>('', ['required']),
    message: useFormField<string>('', ['required']),
    terms: useFormField<boolean>(false, ['required']),
  } as const;

  async function wait(ms) {
    return new Promise(function (resolve, reject) {
      setTimeout(resolve, ms);
    });
  }

  async function onSubmit() {
    // await wait(2000); // Case  loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return; // Case success

    const resp = await fetch('/.netlify/functions/send-email-sendgrid', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: fieldsByName.name.value,
        email: fieldsByName.email.value,
        phone: fieldsByName.phone.value,
        message: fieldsByName.message.value,
      }),
    });
    if (resp.status !== 200) {
      throw new Error('Got response with status code ' + resp.status);
    }
    withDataLayer(dataLayer => {
      dataLayer.push({ event: 'contactForm', type: 'submission' });
    });
  }

  const { getFieldProps, renderSubmitButton } = useForm({
    fieldsByName,
    onSubmit,
  });

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <span className={styles.topInfo}>
          {t(
            'module_contact_form.info',
            'If you prefer having us contacting you, follow the steps and let us know who you are and what you need so we can contact you with all the information you need.',
          )}
        </span>
        <span className={styles.topInfo}>
          <FontAwesomeIcon icon="info-circle" className={styles.infoIcon} />
          {t('module_contact_form.mandatory_items_info', 'Mandatory items are marked with an *')}
        </span>

        <div className={styles.content}>
          <div className={styles.blockContainer}>
            <span className={styles.blueBordersLabel}>
              {t('module_contact_form.step1_title', 'Step 1 - Who You Are')}
            </span>
            <div className={styles.block + ' ' + styles.stepOneBlock}>
              <div className={styles.infoBlock}>
                <TextField
                  label={t('module_contact_form.label.name', 'Your full name') + ' *'}
                  type="name"
                  name="name"
                  placeholder={t('module_contact_form.placeholder.name', 'John Doe')}
                  {...getFieldProps(fieldsByName.name)}
                />
              </div>

              <div className={styles.infoBlock}>
                <TextField
                  label={t('module_contact_form.label.email', 'Your email') + ' *'}
                  type="email"
                  name="email"
                  placeholder={t('module_contact_form.placeholder.email', 'name@mailprovider.com')}
                  {...getFieldProps(fieldsByName.email)}
                />
              </div>

              <div className={styles.infoBlock}>
                <TextField
                  label={t('module_contact_form.label.phone', 'Your phone number') + ' *'}
                  type="phone"
                  name="phone"
                  placeholder={t('module_contact_form.placeholder.phone', '+351 965492727')}
                  {...getFieldProps(fieldsByName.phone)}
                />
              </div>
            </div>
            {/* Empty FormHelperText to force alignment with stepThreeBlock */}
            <FormHelperText> </FormHelperText>
          </div>

          <div className={styles.blockContainer}>
            <span className={styles.blueBordersLabel}>
              {t('module_contact_form.step2_title', 'Step 2 - How can we help')}
            </span>
            <div className={styles.block + ' ' + styles.stepTwoBlock}>
              <TextField
                multiline
                label={t('module_contact_form.label.message', 'Your message') + ' *'}
                className={styles.textArea}
                name="message"
                placeholder={t('module_contact_form.placeholder.help', 'Tell us how we can help')}
                {...getFieldProps(fieldsByName.message, {
                  setValuePreprocessor: value => value.substr(0, MAX_MESSAGE_CHARACTERS),
                  defaultHelperText: t(
                    'module_contact_form.chars_remaining_text',
                    '{{n_characters}} characters remaining',
                    { n_characters: MAX_MESSAGE_CHARACTERS - fieldsByName.message.value.length },
                  ),
                })}
              />
            </div>
            {/* Empty FormHelperText to force alignment with stepThreeBlock */}
            <FormHelperText> </FormHelperText>
          </div>

          <div className={styles.blockContainer}>
            <span className={styles.blueBordersLabel}>
              {t('module_contact_form.step3_title', 'Step 3 - Accept Terms')}
            </span>
            <div className={styles.block + ' ' + styles.stepThreeBlock}>
              <div className={styles.infoBlock}>
                <CheckboxField
                  topLabel={
                    t('module_contact_form.accept_terms.label', 'Terms of Data Protection') + ' *'
                  }
                  checkboxLabel={
                    <Trans
                      i18nKey="module_contact_form.accept_terms.text"
                      defaults="I accept the <termsLink>Terms of Data Protection</termsLink>."
                      components={{
                        termsLink: (
                          <GenericLink className={styles.link} to={'/' + reference.slug.current}>
                            _
                          </GenericLink>
                        ),
                      }}
                    />
                  }
                  name="terms"
                  {...getFieldProps(fieldsByName.terms)}
                ></CheckboxField>
              </div>
              {renderSubmitButton({
                readyLabel: t('module_contact_form.button.ready', 'Send Contact Request'),
                submittingLabel: t('module_contact_form.button.submitting', 'Sending Request'),
                errorLabel: t('module_contact_form.button.error', 'Something went wrong'),
                submittedLabel: t('module_contact_form.button.submitted', 'Contact request sent!'),
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFormModule;
