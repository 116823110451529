import React from 'react';
import { graphql, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import * as styles from './index.module.scss';

export const ButtonModuleFragment = graphql`
  fragment ButtonModule on SanityButtonModule {
    text {
      ...LocaleStringFragment
    }
    url
    pageReference {
      slug {
        current
      }
    }
  }
`;

export type ButtonModuleProps = {
  text: string;
} & (
  | {
      url: string;
      pageReference?: never;
    }
  | {
      url?: never;
      pageReference: {
        slug: {
          current: string;
        };
      };
    }
);

const ButtonModule = ({ text, url, pageReference }: ButtonModuleProps): React.ReactElement => {
  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        <Link
          className={styles.buttonContainer}
          to={pageReference ? '/' + pageReference.slug.current : url}
        >
          {text}
        </Link>
      </div>
    </div>
  );
};

export default ButtonModule;
