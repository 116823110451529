import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { FaChevronRight } from 'react-icons/fa';
import Accordion from '../../Accordion';
import Card from '../../Card';

import * as styles from './index.module.scss';

export const TabbedCardListModuleFragment = graphql`
  fragment TabbedCardListModule on SanityTabbedCardListModule {
    title {
      ...LocaleStringFragment
    }
    introText: mainText {
      ...LocaleTextFragment
    }
    tabs {
      title {
        ...LocaleStringFragment
      }
      subtitle {
        ...LocaleStringFragment
      }
      cards {
        title {
          ...LocaleStringFragment
        }
        description {
          ...LocaleTextFragment
        }
        image {
          asset {
            url
          }
        }
      }
      color {
        hex
      }
    }
  }
`;

export interface TabbedCardListModuleProps {
  title?: string;
  introText?: string;
  tabs: Array<{
    title: string;
    subtitle: string;
    cards: Array<{
      title: string;
      description?: string;
      image: {
        asset: {
          url: string;
        };
      };
    }>;
    color: {
      hex: string;
    };
  }>;
}

const TabbedCardListModule = ({
  title,
  introText,
  tabs,
}: TabbedCardListModuleProps): React.ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {introText && <p className={styles.mainText}>{introText}</p>}
        <div className={styles.tabsMobile}>
          {tabs.map((tab, i) => (
            <Accordion
              key={i}
              className={styles.accordion}
              title={tab.title}
              subtitle={tab.subtitle}
              color={tab.color.hex}
            >
              <div className={styles.card}>
                {tab.cards.map((card, i) => (
                  <Card
                    key={i}
                    title={card.title}
                    image={card.image.asset.url}
                    description={card.description}
                  ></Card>
                ))}
              </div>
            </Accordion>
          ))}
        </div>
        <div className={styles.tabsDesktopContainer}>
          <nav className={styles.tabs}>
            {tabs.map((tab, i) => (
              <div
                key={i}
                className={styles.tab + ' ' + (activeTabIndex === i ? styles.active : '')}
              >
                <div className={styles.colorBar} style={{ backgroundColor: tab.color.hex }}></div>
                <div className={styles.content} onClick={() => setActiveTabIndex(i)}>
                  <div className={styles.titleAndSubtitle}>
                    <span className={styles.title}>{tab.title}</span>
                    {tab.subtitle && <span className={styles.subtitle}>{tab.subtitle}</span>}
                  </div>
                  <FaChevronRight className={styles.icon}></FaChevronRight>
                </div>
              </div>
            ))}
          </nav>
          <div className={styles.cardsContainer}>
            {tabs[activeTabIndex].cards.map((card, cardIndex) => (
              <div key={activeTabIndex + '-' + cardIndex} className={styles.cards}>
                <Card
                  title={card.title}
                  description={card.description}
                  image={card.image.asset.url}
                  color={tabs[activeTabIndex].color.hex}
                ></Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabbedCardListModule;
