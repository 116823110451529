import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './index.module.scss';

export const ImageModuleFragment = graphql`
  fragment ImageModuleImage on SanityImage {
    asset {
      gatsbyImageData(
        layout: CONSTRAINED
        width: 1600
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }

  fragment ImageModuleMobileImage on SanityImage {
    asset {
      gatsbyImageData(
        layout: CONSTRAINED
        width: 780
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }

  fragment ImageModule on SanityImageModule {
    title {
      ...LocaleStringFragment
    }
    imageAlignment
    image {
      __typename
      en {
        ...ImageModuleImage
      }
      pt {
        ...ImageModuleImage
      }
      es {
        ...ImageModuleImage
      }
      fr {
        ...ImageModuleImage
      }
      it {
        ...ImageModuleImage
      }
      ro {
        ...ImageModuleImage
      }
    }
    mobileImage {
      __typename
      en {
        ...ImageModuleMobileImage
      }
      pt {
        ...ImageModuleMobileImage
      }
      es {
        ...ImageModuleMobileImage
      }
      fr {
        ...ImageModuleMobileImage
      }
      it {
        ...ImageModuleMobileImage
      }
      ro {
        ...ImageModuleMobileImage
      }
    }
    link {
      url
      text {
        ...LocaleStringFragment
      }
    }
  }
`;

export interface ImageModuleProps {
  title?: string;
  imageAlignment?: 'left' | 'center';
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  mobileImage?: {
    asset?: {
      gatsbyImageData?: IGatsbyImageData;
    };
  };
  link?: {
    url?: string;
    text?: string;
  };
}

const ImageModule = ({
  title,
  imageAlignment,
  image,
  mobileImage,
  link,
}: ImageModuleProps): React.ReactElement => {
  return (
    <div className={styles.moduleRoot + (imageAlignment === 'left' ? ' ' + styles.alignLeft : '')}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.container}>
        {mobileImage && mobileImage.asset && mobileImage.asset.gatsbyImageData && (
          <GatsbyImage
            alt=""
            imgClassName={styles.image}
            className={styles.mobileImageContainer}
            image={mobileImage.asset.gatsbyImageData}
          ></GatsbyImage>
        )}
        <GatsbyImage
          alt=""
          imgClassName={styles.image}
          className={styles.imageContainer}
          image={image.asset.gatsbyImageData}
        ></GatsbyImage>
        {link && link.url && link.text && (
          <button onClick={() => window.open(link.url)} className={styles.link}>
            {link.text}
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageModule;
