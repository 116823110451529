// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var card = "index-module--card--fE7R9";
export var cardParagraph = "index-module--cardParagraph--qCsZd";
export var container = "index-module--container--XnQ-K";
export var content = "index-module--content--YaEh3";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "index-module--image--Qtjos";
export var imageContainer = "index-module--imageContainer--ZoGt6";
export var imageWrapper = "index-module--imageWrapper--wHzWN";
export var infoContent = "index-module--infoContent--+Znw-";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "index-module--link--cphkj";
export var linkIcon = "index-module--linkIcon--uSFbW";
export var moduleRoot = "index-module--moduleRoot--WbxGB";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var title = "index-module--title--0hW+a";
export var titleBar = "index-module--titleBar--Ucajj";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";