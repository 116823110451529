// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--7ujes";
export var content = "index-module--content--+YhUo";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var date = "index-module--date--zUZbw";
export var description = "index-module--description--VquMn";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var iframe = "index-module--iframe--gksNl";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--gylQI";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var title = "index-module--title--EGArX";
export var videoTitle = "index-module--videoTitle--t2mKp";
export var warning = "#ffeda0";
export var white = "#fff";
export var withBackground = "index-module--withBackground--SP9es";
export var yellow = "#fbc900";