import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import {
  fromEntries,
  normalizeString,
  removeItem,
  sortBy,
  withDataLayer,
} from '../../../utils/utils';
import { useFormField, useForm } from '../../../utils/formsV2';
import { FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import GenericLink from '../../GenericLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '../../TextField';
import CheckboxField from '../../CheckboxField';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { useLocalization } from '../../../utils/hooks';

import { COUNTRIES_AND_REGIONS } from '../../../constants/newsletterForm';

import * as styles from './index.module.scss';

export const NewsletterModuleFragment = graphql`
  fragment NewsletterModule on SanityNewsletterModule {
    ... on SanityNewsletterModule {
      label
      title {
        ...LocaleStringFragment
      }
      description {
        ...LocaleTextFragment
      }
    }
  }
`;

export interface NewsletterModuleProps {
  title?: string;
  description?: string;
}

const STEP_TRANSITION_DELAY = 1500;

interface LocalizedQueryData {
  site: {
    siteMetadata: {
      hasNewsletter: boolean;
    };
  };
  sanityWebsite: {
    reference: {
      slug: {
        current: string;
      };
    };
  };
}

const NewsletterModule = ({ title, description }: NewsletterModuleProps): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            hasNewsletter
          }
        }
        sanityWebsite {
          reference {
            slug {
              current
            }
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);

  const { hasNewsletter } = localizedData.site.siteMetadata;
  if (hasNewsletter === false) {
    throw new Error('Please check that all necessary mailchimp environment variables are set');
  }

  const termsLink = localizedData.sanityWebsite || {};

  const countries = COUNTRIES_AND_REGIONS.map(({ country }) => country);

  const countriesWithOther = [...countries, 'Other'];

  const areasOfInterest = [
    { name: 'General Company Information' },
    { name: 'Bioprotection Products' },
    { name: 'Conventional Products' },
  ];

  const regionsByCountry = fromEntries(
    COUNTRIES_AND_REGIONS.map(({ country, regions }) => [
      country,
      sortBy(regions, region => normalizeString(region)),
    ]),
  );

  const [formStep, setFormStep] = useState<'step1' | 'step2'>('step1');

  const form1FieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    terms: useFormField<boolean>(false, ['required']),
  } as const;

  const form2FieldsByName = {
    country: useFormField<string>(countriesWithOther[0], []),
    region: useFormField<string>(regionsByCountry[countriesWithOther[0]][0], []),
    otherCountry: useFormField<string>('', []),
    areasOfInterest: useFormField<string>('', []),
  } as const;

  useEffect(() => {
    if (form2FieldsByName.country.value !== 'Other') {
      form2FieldsByName.region.setValue(regionsByCountry[form2FieldsByName.country.value][0]);
    }
  }, [form2FieldsByName.country.value]);

  const currentCountryRegions = regionsByCountry[form2FieldsByName.country.value];

  async function wait(ms: number) {
    return new Promise(function (resolve, reject) {
      setTimeout(resolve, ms);
    });
  }

  async function onSubmit() {
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
    const data = {
      name: form1FieldsByName.name.value,
      email: form1FieldsByName.email.value,
      terms: form1FieldsByName.terms.value,
      country: formStep === 'step1' ? '' : form2FieldsByName.country.value,
      region:
        formStep === 'step1' || form2FieldsByName.country.value === 'Other'
          ? ''
          : form2FieldsByName.region.value,
      otherCountry:
        form2FieldsByName.country.value === 'Other' ? form2FieldsByName.otherCountry.value : '',
      areasOfInterest: form2FieldsByName.areasOfInterest.value,
    };
    const resp = await fetch('/.netlify/functions/subscribe-newsletter', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (resp.status !== 200) {
      throw new Error('Got response with status code ' + resp.status);
    }
    withDataLayer(dataLayer => {
      dataLayer.push({ event: 'newsletter', type: 'submission', step: formStep });
    });
    return true;
  }

  const formTranslateFunction = (key: string, defaultText: string) => {
    const text = {
      'form.success_message': '',
    }[key as string];

    return text !== undefined ? text : defaultText;
  };

  const form1 = useForm({
    fieldsByName: form1FieldsByName,
    onSubmit: async () => {
      const success = await onSubmit();
      if (success) {
        setTimeout(() => {
          setFormStep('step2');
        }, STEP_TRANSITION_DELAY);
      }
      return success;
    },
    translateFunction: formTranslateFunction,
  });

  const form2 = useForm({
    fieldsByName: form2FieldsByName,
    onSubmit: async () => {
      return await onSubmit();
    },
    translateFunction: formTranslateFunction,
  });

  const subscribeLabels = {
    disabled: t('module_newsletter.subscribe_button', 'Subscribe'),
    ready: t('module_newsletter.subscribe_button', 'Subscribe'),
    submitting: t('module_newsletter.subscribe_button_subscribing', 'Subscribing'),
    error: t('module_newsletter.subscribe_button_error', 'Something went wrong'),
    submitted: t('module_newsletter.subscribe_button_success', 'Subscribed!'),
  };

  const submitLabels = {
    disabled: t('module_newsletter.submit_button', 'Submit'),
    ready: t('module_newsletter.submit_button', 'Submit'),
    submitting: t('module_newsletter.submit_button_continuing', 'Submitting'),
    error: t('module_newsletter.submit_button_error', 'Something went wrong'),
    submitted: t('module_newsletter.submit_button_success', 'Submitted!'),
  };

  const formButtonClasses = {
    common: styles.submitButton,
    ready: styles.submitButtonReady,
    disabled: styles.submitButtonDisabled,
    submitting: styles.submitButtonSubmitting,
    error: styles.submitButtonError,
    submitted: styles.submitButtonSubmitted,
  };

  const formIconClasses = {
    submitting: <AutorenewIcon className={styles.submitButtonLoadingIcon}></AutorenewIcon>,
    error: <ErrorIcon></ErrorIcon>,
    submitted: <CheckIcon></CheckIcon>,
  };

  function renderFormMessage(form: typeof form1 | typeof form2) {
    return (
      <div className={styles.formMessage}>
        <FormHelperText>{form.formMessage || ' '}</FormHelperText>
        <FormHelperText>
          {!!form.formMessage && form.hasFormError ? (
            <Trans
              i18nKey="module_newsletter.error_please_try_again"
              defaults="Please <0>try again</0>."
              components={[
                <GenericLink key="link" styleOnly onClick={form.clearFormError}>
                  _
                </GenericLink>,
              ]}
            />
          ) : (
            ' '
          )}
        </FormHelperText>
      </div>
    );
  }

  return (
    <div className={styles.moduleRoot}>
      <div className={styles.container}>
        {title && <h3 className={styles.title}>{title}</h3>}

        {formStep === 'step1' && <span className={styles.topInfo}>{description}</span>}

        <span className={styles.topInfo}>
          <FontAwesomeIcon icon="info-circle" className={styles.infoIcon} />
          {t('module_newsletter.mandatory_items_notice', 'Mandatory items are marked with an')} *
        </span>

        {formStep === 'step2' && (
          <div className={styles.subscriptionBoxInfo}>
            <span>
              <Trans
                i18nKey="module_newsletter.subscription_form2_notice"
                defaults="<bold>Thank you for your subscription!</bold> Tell us more about yourself below to make future contacts easier."
                components={{ bold: <strong /> }}
              />
            </span>
          </div>
        )}

        {formStep === 'step1' && (
          <div className={styles.content}>
            <div className={styles.blockContainer}>
              <span className={styles.blueBordersLabel}>
                {t('module_newsletter.step_1_title', 'Step 1 - Enter your data')}
              </span>
              <div className={styles.block + ' ' + styles.stepOneBlock}>
                <div className={styles.infoBlock}>
                  <TextField
                    label={t('module_newsletter.name_label', 'Your name') + ' *'}
                    type="name"
                    name="name"
                    placeholder={t('module_newsletter.name_placeholder', 'Name')}
                    {...form1.getFieldProps(form1FieldsByName.name)}
                  />
                </div>
                <div className={styles.infoBlock}>
                  <TextField
                    label={t('module_newsletter.email_label', 'Your email') + ' *'}
                    type="email"
                    name="email"
                    placeholder={t('module_newsletter.email_placeholder', 'name@mailprovider.com')}
                    {...form1.getFieldProps(form1FieldsByName.email)}
                  />
                </div>
              </div>
              {/* Empty FormHelperText to force alignment with stepThreeBlock */}
              <FormHelperText> </FormHelperText>
            </div>

            <div className={styles.blockContainer}>
              <span className={styles.blueBordersLabel}>
                {t('module_newsletter.step_2_title', 'Step 2 - Accept Terms')}
              </span>
              <div className={styles.block + ' ' + styles.stepTwoBlock}>
                <div className={styles.infoBlock}>
                  <CheckboxField
                    topLabel={t('module_newsletter.terms_label', 'Terms of Data Protection') + ' *'}
                    checkboxLabel={
                      <Trans
                        i18nKey="module_newsletter.accept_terms_checkbox_with_link"
                        defaults="I accept the <0>Terms of Data Protection</0>"
                        components={
                          termsLink
                            ? [
                                <GenericLink
                                  key={'newsletterKey'}
                                  to={'/' + termsLink.reference.slug.current}
                                  className={styles.genericLink}
                                >
                                  _
                                </GenericLink>,
                              ]
                            : [<span key="_">_</span>]
                        }
                      />
                    }
                    name="terms"
                    {...form1.getFieldProps(form1FieldsByName.terms)}
                  ></CheckboxField>
                </div>
              </div>

              {/* Empty FormHelperText to force alignment with stepThreeBlock */}
              <FormHelperText> </FormHelperText>
            </div>

            <div className={styles.blockContainer}>
              <span className={styles.blueBordersLabel}>
                {t('module_newsletter.step_3_title', 'Step 3 - Subscribe')}
              </span>
              <div className={styles.block + ' ' + styles.stepThreeBlock}>
                {form1.renderSubmitButton({
                  labels: subscribeLabels,
                  btnClasses: formButtonClasses,
                  iconClasses: formIconClasses,
                })}
                {renderFormMessage(form1)}
              </div>
            </div>
          </div>
        )}

        {formStep === 'step2' && (
          <div className={styles.content}>
            <div className={styles.blockContainer}>
              <span className={styles.blueBordersLabel}>
                {t('module_newsletter.step_4_title', "Step 4 - Where you're from")}
              </span>
              <div className={styles.block}>
                <div className={styles.infoBlock}>
                  <TextField
                    id="country-select"
                    select
                    label={t('module_newsletter.country_label', 'Your country') + ' *'}
                    {...form2.getFieldProps(form2FieldsByName.country)}
                  >
                    {countriesWithOther.map(country => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {form2FieldsByName.country.value !== 'Other' && (
                  <div className={styles.infoBlock}>
                    <TextField
                      id="region-select"
                      select
                      label={t('module_newsletter.region_label', 'Your region') + ' *'}
                      {...form2.getFieldProps(form2FieldsByName.region)}
                      disabled={
                        !currentCountryRegions ||
                        form2.getFieldProps(form2FieldsByName.region).disabled
                      }
                    >
                      {currentCountryRegions?.map(region => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}

                {form2FieldsByName.country.value === 'Other' && (
                  <div className={styles.otherCountryInputContainer}>
                    <TextField
                      name="otherCountry"
                      placeholder={t('module_newsletter.other_country_placeholder', 'Country')}
                      {...form2.getFieldProps(form2FieldsByName.otherCountry)}
                    />
                  </div>
                )}
              </div>
              {/* Empty FormHelperText to force alignment with stepThreeBlock */}
              <FormHelperText> </FormHelperText>
            </div>

            <div className={styles.blockContainer}>
              <span className={styles.blueBordersLabel}>
                {t('module_newsletter.step_5_title', 'Step 5 - Choose Areas of interest')}
              </span>
              <div className={styles.block}>
                <div className={styles.infoBlock}>
                  <InputLabel shrink>
                    {t('module_newsletter.areas_of_interest_label', 'Areas of interest')}
                  </InputLabel>
                  <div className={styles.areasContainer}>
                    {areasOfInterest.map((area, i) => {
                      const areasList = form2FieldsByName.areasOfInterest.value
                        ? form2FieldsByName.areasOfInterest.value.split(';')
                        : [];
                      const isAreaOfInterestChecked = areasList.includes(area.name);

                      return (
                        <CheckboxField
                          key={i}
                          checkboxLabel={area.name}
                          name={area.name}
                          small
                          className={styles.areaCheckbox}
                          disabled={form2.submitState === 'submitted'}
                          onBlur={() => form2.onFieldUnfocus(form2FieldsByName.areasOfInterest)}
                          error={!!form2FieldsByName.areasOfInterest.error}
                          helperText=""
                          value={isAreaOfInterestChecked}
                          onChange={() =>
                            form2FieldsByName.areasOfInterest.setValue(
                              (isAreaOfInterestChecked
                                ? removeItem(areasList, area.name)
                                : [...areasList, area.name]
                              ).join(';'),
                            )
                          }
                        ></CheckboxField>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.blockContainer}>
              <span className={styles.blueBordersLabel}>
                {t('module_newsletter.step_6_title', 'Step 6 - Submit')}
              </span>
              <div className={styles.block}>
                {form2.renderSubmitButton({
                  labels: submitLabels,
                  btnClasses: formButtonClasses,
                  iconClasses: formIconClasses,
                })}
                {renderFormMessage(form2)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterModule;
