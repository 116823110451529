// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var card = "EventCard-module--card--b65Lf";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var date = "EventCard-module--date--j4abE";
export var dateContainer = "EventCard-module--dateContainer--tSpTs";
export var dateIcon = "EventCard-module--dateIcon--GopAl";
export var description = "EventCard-module--description--y1faI";
export var error = "#f66a83";
export var eventInfoIcon = "EventCard-module--eventInfoIcon--+1DXq";
export var externalLinkContainer = "EventCard-module--externalLinkContainer--RHmTb";
export var externalLinkIcon = "EventCard-module--externalLinkIcon--MeE2D";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "EventCard-module--image--h+YMB";
export var imageContainer = "EventCard-module--imageContainer--io2It";
export var info = "EventCard-module--info--cAMLV";
export var infoAndImageContainer = "EventCard-module--infoAndImageContainer--hUMjF";
export var infoContainer = "EventCard-module--infoContainer--HkHDo";
export var leftContainer = "EventCard-module--leftContainer--Tqwj2";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "EventCard-module--link--kJvqC";
export var mobileImageContainer = "EventCard-module--mobileImageContainer--UXiOX";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";