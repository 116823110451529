import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { graphql } from 'gatsby';
import HeroSlide from './HeroSlide';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { VersatileLinkMaybeEmpty } from '../graphql-fragments/versatileLink';
import { getUrlFromVersatileLink } from '../utils/utils';

import * as styles from './HeroSlider.module.scss';

export const HeroSliderFragment = graphql`
  fragment HeroSlide on SanityHeroSlide {
    image {
      __typename
      en {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      pt {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      es {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      fr {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }

      it {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      ro {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 810
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    mobileImage {
      __typename
      en {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 2300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      pt {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 2300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      es {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 2300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      fr {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 2300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }

      it {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 2300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      ro {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 2300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    imageHasText
    overlayOverImage
    title {
      ...LocaleStringFragment
    }
    description {
      ...LocaleStringFragment
    }
    buttonText {
      ...LocaleStringFragment
    }
    url {
      ...LocaleVersatileLink
    }
    buttonPosition
  }
  fragment HeroSlider on SanityHeroSlider {
    slides {
      ...HeroSlide
    }
  }
`;

export interface HeroSlide {
  url?: VersatileLinkMaybeEmpty;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  mobileImage: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  imageHasText: boolean;
  overlayOverImage: boolean;
  buttonPosition: 'top' | 'center' | 'bottom';
  title: string;
  description: string;
  buttonText: string;
}

export interface HeroSliderProps {
  slides: Array<HeroSlide>;
}

function countWords(text: string): number {
  return text.split(/\s/).length;
}

const HeroSlider = ({ slides }: HeroSliderProps): React.ReactElement => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [wasInteractedWith, setWasInteractedWith] = useState(false);
  const currentTimeout = useRef<NodeJS.Timeout | null>(null);

  function goToNextSlideAfterDelay() {
    currentTimeout.current = setTimeout(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, Math.max(1000 + countWords(slides[currentSlide].title + ' ' + slides[currentSlide].description) * 300, 4000));
  }

  useEffect(() => {
    if (!wasInteractedWith) {
      goToNextSlideAfterDelay();
    }
  }, [currentSlide]);

  return (
    <div className={styles.container}>
      {slides.map((slide, index) => (
        <div
          key={index}
          className={styles.slide + (index !== currentSlide ? ' ' + styles.hidden : '')}
        >
          <HeroSlide
            {...slide}
            buttonUrl={
              slide.url?.url || slide.url?.pageReference
                ? getUrlFromVersatileLink(slide.url)
                : undefined
            }
          ></HeroSlide>
        </div>
      ))}
      {slides.length > 1 && (
        <div className={styles.buttonsContent}>
          {slides.map((slide, index) => (
            <div
              key={index}
              onClick={() => {
                if (currentSlide !== index) {
                  if (currentTimeout.current !== null) {
                    clearTimeout(currentTimeout.current);
                  }
                  setCurrentSlide(index);
                  setWasInteractedWith(true);
                }
              }}
              className={
                styles.button + (index === currentSlide ? ' ' + styles.selectedButton : '')
              }
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeroSlider;
