import React from 'react';

import Counter from './Counter';

interface NumberCounterProps {
  numberStr: string;
  hasCountUpStarted: boolean;
  className?: string;
}

const NumberCounter = ({
  numberStr,
  hasCountUpStarted,
  className,
}: NumberCounterProps): React.ReactElement => {
  const numberRegex = /(\d+(?:[,.]\d+)?)/;
  const numberParts = numberStr.trim().split(numberRegex).filter(Boolean);

  return (
    <>
      {numberParts.map((numberPart, index) => {
        if (numberPart.match(numberRegex)) {
          const decimalsMatch = numberPart.match(/\d+[,.](\d+)/);
          const number = parseFloat(numberPart.replace(',', '.'));

          return (
            <Counter
              key={index}
              maxValue={number}
              decimalCases={decimalsMatch ? decimalsMatch[1].length : 0}
              running={hasCountUpStarted}
              className={className}
            ></Counter>
          );
        } else {
          return (
            <span key={index} className={className}>
              {numberPart
                .split(/( )/)
                .map((str, i) =>
                  str === ' ' ? <React.Fragment key={i}>&nbsp;</React.Fragment> : str,
                )}
            </span>
          );
        }
      })}
    </>
  );
};

export default NumberCounter;
