import React, { useEffect, useState } from 'react';

interface CounterProps {
  initialValue?: number;
  steps?: number;
  timeToMaxValueMs?: number;
  maxValue: number;
  timeFunction?: 'linear' | 'ease';
  decimalCases?: number;
  running?: boolean;
  className?: string;
}

function easeInQuad(x: number): number {
  return x * x;
}

const Counter = ({
  initialValue = 0,
  steps = 25,
  timeToMaxValueMs = 2500,
  maxValue,
  timeFunction = 'ease',
  decimalCases = 0,
  running = true,
  className,
}: CounterProps): React.ReactElement => {
  const [currentStep, setCurrentStep] = useState(0);
  const count = initialValue + ((maxValue - initialValue) * currentStep) / steps;

  useEffect(() => {
    if (running && count < maxValue) {
      const incrementIntervalMs = {
        linear: timeToMaxValueMs / steps,
        ease:
          timeToMaxValueMs *
          (easeInQuad((currentStep + 1) / steps) - easeInQuad(currentStep / steps)),
      }[timeFunction];
      const timeoutId = setTimeout(() => {
        if (running) {
          setCurrentStep(currentStep + 1);
        }
      }, incrementIntervalMs);
      return () => clearTimeout(timeoutId);
    }
  }, [count, running]);

  return (
    <div className={className}>
      {(Math.round(count * 10 ** decimalCases) / 10 ** decimalCases).toFixed(decimalCases)}
    </div>
  );
};

export default Counter;
