// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--tcNHP";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var group = "index-module--group--VAVXJ";
export var groupTitle = "index-module--groupTitle--1DanH";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--F2+3w";
export var numberContainer = "index-module--numberContainer--7i3d5";
export var numbersContainer = "index-module--numbersContainer--NBQy2";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var text = "index-module--text--ICtSE";
export var title = "index-module--title--wKdXN";
export var titleContainer = "index-module--titleContainer--67W8M";
export var value = "index-module--value--5XBbT";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";