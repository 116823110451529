// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var block = "index-module--block--hZMzT";
export var blockContainer = "index-module--blockContainer--pQTpY";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "index-module--container--yjHCB";
export var content = "index-module--content--Nn33K";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "index-module--image--Ozv45";
export var imageContainer = "index-module--imageContainer--2Uz+A";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var line = "index-module--line--Vejxl";
export var mobileImageContainer = "index-module--mobileImageContainer--9QI5C";
export var moduleRoot = "index-module--moduleRoot--leI9N";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var reverse = "index-module--reverse--RKaWs";
export var success = "#1ebc7b";
export var textBox = "index-module--textBox--0DtOe";
export var tnITitle = "index-module--tnITitle--SlrH0";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";