// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var bulletIcon = "index-module--bulletIcon--zzs1m";
export var bulletPointBox = "index-module--bulletPointBox--DATpe";
export var bulletPointsList = "index-module--bulletPointsList--dNa-R";
export var bulletPointsSection = "index-module--bulletPointsSection--Mtk5+";
export var bulletPointsSingleTitle = "index-module--bulletPointsSingleTitle--TCHEg";
export var container = "index-module--container--9Q7lm";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var headingIntroText = "index-module--headingIntroText--vhn2+";
export var image = "index-module--image--NiGYj";
export var imageContainer = "index-module--imageContainer--DXqzA";
export var introText = "index-module--introText--mbaSI";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--Hdhas";
export var orange = "#fc9948";
export var outroText = "index-module--outroText--GYkJr";
export var paleBlue = "#64a1bb";
export var paragraphIntroText = "index-module--paragraphIntroText--HdeiM";
export var success = "#1ebc7b";
export var textBlock = "index-module--textBlock--+Tsb8";
export var title = "index-module--title--BUnP4";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";