// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var bulletPoint = "index-module--bulletPoint--yO7bf";
export var bulletPointRectangle = "index-module--bulletPointRectangle--T4IeS";
export var bulletPointsBox = "index-module--bulletPointsBox--BBYD6";
export var container = "index-module--container--xHESu";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var imageContainer = "index-module--imageContainer--HpLl6";
export var level = "index-module--level--Hx5ID";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var line = "index-module--line--mPkoT";
export var linkIcon = "index-module--linkIcon--qCyJY";
export var moduleRoot = "index-module--moduleRoot--+ozLT";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var paragraph = "index-module--paragraph--E0lix";
export var pyramidContainer = "index-module--pyramidContainer--gdSbR";
export var rectangle = "index-module--rectangle--6tICY";
export var seeMoreContainer = "index-module--seeMoreContainer--l8T25";
export var seeMoreLink = "index-module--seeMoreLink--ytUPW";
export var seeMoreOpen = "index-module--seeMoreOpen--U3lE5";
export var success = "#1ebc7b";
export var textContent = "index-module--textContent--N4upP";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";