// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var bulletIcon = "index-module--bulletIcon--ZwJ7e";
export var bulletList = "index-module--bulletList--I7niD";
export var bulletPointBox = "index-module--bulletPointBox--QRwDS";
export var bulletPointBoxBackground = "index-module--bulletPointBoxBackground--5E+JQ";
export var bulletPointRectangle = "index-module--bulletPointRectangle--br3Eo";
export var bulletPointsList = "index-module--bulletPointsList--1uYZp";
export var bulletPointsSingleTitle = "index-module--bulletPointsSingleTitle--jgKQ4";
export var container = "index-module--container--+g8qm";
export var content = "index-module--content--wpCV1";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "index-module--image--et2XP";
export var imageContainer = "index-module--imageContainer--wU7oU";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var moduleRoot = "index-module--moduleRoot--wZB7J";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var textBlock = "index-module--textBlock--OPBPw";
export var title = "index-module--title--8xKbx";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";