import React from 'react';
import { clsx, replaceNewLinesWithBr } from '../utils/utils';

import * as styles from './Card.module.scss';

interface CardProps {
  className?: string;
  image: string;
  title: string;
  description?: string;
  color?: string;
}

const Card = ({ className, image, title, description, color }: CardProps): React.ReactElement => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.colorBar} style={{ backgroundColor: color }}></div>
      <div className={styles.card}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={image} alt="" />
        </div>
        <div className={styles.titleAndDescription}>
          <span className={styles.title}>{title}</span>
          {description && (
            <p className={styles.description}>{replaceNewLinesWithBr(description)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
