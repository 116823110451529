import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { FaChevronRight, FaPlayCircle } from 'react-icons/fa';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import clsx from 'clsx';

import GenericLink from './GenericLink';
import { useTranslation } from 'react-i18next';
import getYouTubeID from 'get-youtube-id';
import usePortal from 'react-useportal';
import FsLightbox from 'fslightbox-react';

import * as styles from './HeroSlide.module.scss';

export interface HeroSlideProps {
  title?: string;
  description?: string;
  buttonUrl?: string;
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  mobileImage: {
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  imageHasText?: boolean;
  overlayOverImage?: boolean;
  buttonPosition?: 'top' | 'center' | 'bottom';
  buttonText?: string;
}

const HeroSlide = ({
  title,
  description,
  buttonUrl,
  image,
  mobileImage,
  imageHasText,
  overlayOverImage,
  buttonPosition,
  buttonText,
}: HeroSlideProps): React.ReactElement => {
  const { t } = useTranslation();

  const [lightboxToggler, setLightboxToggler] = useState(false);
  const { Portal } = usePortal();

  const isUrlAVideo = !!(
    buttonUrl &&
    buttonUrl.match(
      /^(https?:\/\/)?((www\.)?youtube\.com\/(watch\?v=|embed\/)|(youtu\.be\/))[\w-]+/,
    )
  );

  const id = isUrlAVideo ? getYouTubeID(buttonUrl) : '';
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  return (
    <div className={styles.container}>
      {!!mobileImage && (
        <GatsbyImage
          alt={''}
          className={styles.mobileImageContainer}
          imgClassName={styles.image}
          image={mobileImage.asset.gatsbyImageData}
        ></GatsbyImage>
      )}
      <GatsbyImage
        alt={''}
        className={styles.imageContainer}
        imgClassName={styles.image}
        image={image.asset.gatsbyImageData}
      ></GatsbyImage>
      {(overlayOverImage || !imageHasText) && <div className={styles.overlay}></div>}
      <div
        className={clsx(
          styles.content,
          !imageHasText && styles.contentWithDefaultButton,
          imageHasText && buttonPosition === 'top' && styles.topButton,
          imageHasText && buttonPosition === 'bottom' && styles.bottomButton,
        )}
      >
        {!imageHasText && (
          <div className={styles.textContainer}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {description && <p className={'titleParagraph ' + styles.paragraph}>{description}</p>}
          </div>
        )}
        {buttonUrl && buttonText && (
          <GenericLink
            noStyle
            className={clsx('titleParagraph ' + styles.link)}
            to={'#'}
            onClick={() => {
              isUrlAVideo ? setLightboxToggler(!lightboxToggler) : navigate(buttonUrl);
            }}
          >
            <div
              className={clsx(
                styles.button,
                imageHasText && !overlayOverImage && styles.buttonWithBackground,
              )}
            >
              {buttonText}
              {isUrlAVideo ? (
                <FaPlayCircle className={clsx(styles.linkIcon, styles.playIcon)} />
              ) : (
                <FaChevronRight className={styles.linkIcon}></FaChevronRight>
              )}
            </div>
          </GenericLink>
        )}
        <Portal>
          <FsLightbox
            toggler={lightboxToggler}
            sources={[
              // @ts-ignore
              <iframe
                key="video"
                className={styles.iframe}
                src={embedUrl + '?autoplay=1'}
              ></iframe>,
            ]}
          ></FsLightbox>
        </Portal>
      </div>
    </div>
  );
};

export default HeroSlide;
