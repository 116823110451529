// extracted by mini-css-extract-plugin
export var accordion = "Accordion-module--accordion--o-k+X";
export var accordionBox = "Accordion-module--accordionBox--r+xTF";
export var accordionOpen = "Accordion-module--accordionOpen--yX2A2";
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var body = "Accordion-module--body--sNGTU";
export var colorBar = "Accordion-module--colorBar--yKubK";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var footerLink = "Accordion-module--footerLink--tCsbJ";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "Accordion-module--icon--ERK5B";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var title = "Accordion-module--title--2OMLa";
export var titleAndSubtitle = "Accordion-module--titleAndSubtitle---1SSK";
export var titleBox = "Accordion-module--titleBox--lCDaW";
export var titleBoxWithColorBar = "Accordion-module--titleBoxWithColorBar--Z438i";
export var titleParagraph = "Accordion-module--titleParagraph--MHwpc";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";