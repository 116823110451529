// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var block = "index-module--block--B1G0c";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var bottomFeaturedParagraph = "index-module--bottomFeaturedParagraph--sc3Yy";
export var button = "index-module--button--sy+55";
export var container = "index-module--container--mq+gG";
export var content = "index-module--content--XudyL";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var featuredParagraphPortableTextContainer = "index-module--featuredParagraphPortableTextContainer--IfGQt";
export var featuredText = "index-module--featuredText--5DovL";
export var featuredTextContainer = "index-module--featuredTextContainer--5EWqE";
export var footerLink = "index-module--footerLink--6EzyW";
export var green = "#3dae2b";
export var grey = "#828382";
export var image = "index-module--image--QbffO";
export var imageContainer = "index-module--imageContainer--t-41q";
export var lightBackground = "index-module--lightBackground---oER3";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var paragraph = "index-module--paragraph--i2k2V";
export var plantIcon = "index-module--plantIcon--CIWcw";
export var reverse = "index-module--reverse--JJWSl";
export var success = "#1ebc7b";
export var textModulePart = "index-module--textModulePart--tVnSF";
export var titleParagraph = "index-module--titleParagraph--j2xLW";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";